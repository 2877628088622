import { CustomeTextfield } from "components/shared/Textfield";
import styled from "styled-components";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import { TableScrollDiv } from "components/manageDns/managedns.style";

export const CartHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const CartHeading = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: var(--primary-text-color);
  margin: 0;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const CartCounter = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: var(--textinput-border-color);
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-text-color);

  @media (${MediaBreakpoints.mobile}) {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
`;

export const DomainCartWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
  align-items: start;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const DomainListWrapper = styled.div`
  width: 50%;
  display: flex;
  fxlex-direction: column;
  justify-content: center;
  align-items: Center;
  gap: 10px;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const CartDomainsList = styled.div`
  background: var(--table-row-background-color);
  border-radius: 8px;
  //   width: 595px;
  height: 56px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all ease 0.3s;

  :hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;

export const CartSummaryCard = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
  gap: 16px;

  width: 511px;
  //   height: 514px;

  background: var(--accountmenu-background-color);
  border: 1px solid #91b6d1;
  border-radius: 24px;

  @media (${MediaBreakpoints.mobile}) {
    padding: 10px;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const CartDomainScroll = styled(TableScrollDiv)`
  height: 500px;

  ${MediaBreakpoints.ipad} {
    height: 350px;
  }
`;

export const CartSummeryHeading = styled.h3`
  margin: 0;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--primary-text-color);

  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const CartDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (${MediaBreakpoints.mobile}) {
    margin-bottom: 0px;
  }
`;

export const Detailkey = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  
  color: var(--primary-text-color);
  
  @media (${MediaBreakpoints.mobile}) {
  font-size: 14px;
  }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--divider-background-color);
`;

export const ReferralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
  @media (${MediaBreakpoints.mobile}) {
    margin-bottom: 0px;
  }
`;

export const ReferralInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--textinput-background-color);
  /* Dark mode/Font/Disabled */

  border: 1px solid var(--divider-background-color);
  border-radius: 100px;

  ${MediaBreakpoints.ipad} {
    justify-content: space-between;
  }
`;

export const RefferDomainTextField = styled(CustomeTextfield)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
    padding: 12px 8px;
  }
  & input {
    border: none;
  }
`;

export const ApplyBtn = styled.button`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Dark mode/Brand/Light */

  color: var(--primary-border-color);
  cursor: pointer;
  background: transparent;
  border: none;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
    padding: 0 10px;
  }
`;

export const DomianName = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-text-color);
`;

export const Duration = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-text-color);
`;

export const CardWrapper = styled.div`
  width: 100%;
  background: var(--skeleton-background-color);
  border-radius: 16px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 16px;
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
