import styled from "styled-components";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";

import { Loading } from "react-loading-dot";
import { ScrollDiv } from "components/myProfile/myprofile.style";
import { ButtonWrapper } from "components/searchList/CheckoutModal.style";
import { CustomeTextfield } from "components/shared/Textfield";
import { TextFieldWrapper } from "components/domainSearch/domain.style";
import { FillButton, OutlineButton } from "components/shared/Buttons";

// export const Loader = styled(Loading)`
//   position: absolute;
//   top: 60% !important;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

export const ModalWrapper = styled.div`
  width: 468px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

export const Text = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Grey/Light */
  margin: 0;
  color: #91b6d1;
`;

export const CreditCard = styled.div`
  border: 1px solid #324b66;
  border-radius: 24px;
  padding: 30px;
  width: 668px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 16px;
    padding: 10px;
  }
`;

export const CreditInputWrapper = styled(TextFieldWrapper)`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const CreditFillButton = styled(FillButton)`
  @media (${MediaBreakpoints.mobile}) {
    padding: 8px;
  }
`;

export const CreditTextField = styled(CustomeTextfield)`
  @media (${MediaBreakpoints.mobile}) {
    & input {
      padding: 10px;
    }
  }
`;

export const MultipleCreditInpurtWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Heading = styled.h1`
  margin: 10px 0;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  /* Font/Regular */

  color: var(--primary-text-color);

  @media (${MediaBreakpoints.mobile}) {
    font-size: 18px;
  }
`;

export const TextFieldLabel = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  /* identical to box height, or 120% */

  /* Font/Regular */

  color: var(--primary-text-color);
`;

export const Multiple = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 10px;
  cursor: pointer;
  /* identical to box height, or 150% */

  text-decoration-line: underline;

  /* Brand/Light */

  color: var(--connectmenu-text-color);
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
`;

export const MultipleInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: end;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`;

export const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;

  @media (${MediaBreakpoints.mobile}) {
    width: 30px;
    height: 30px;
  }
`;

export const DeniedWrapper = styled(ModalWrapper)`
  justify-content: space-between;
`;

export const ScrollWrapper = styled(ScrollDiv)`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0px;
`;

export const DualButtonWrapper = styled(ButtonWrapper)`
  width: 100%;
  justify-content: space-between;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const ParentMultipleInputWrapper = styled.div`
  align-items: start;
  width: 860px;
  gap: 20px;
  margin: 0 auto;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 10px;
  }
`;

export const TextField = styled(CustomeTextfield)`
  width: 250px;
  & input {
    font-size: 12px;
    padding: 10px;
  }

  "&.mui-disabled": {
    color: "red !important";
  }

  @media (${MediaBreakpoints.mobile}) {
    width: 200px;
  }
`;

export const UserInput = styled(CustomeTextfield)`
  width: 100px;
  & input {
    font-size: 12px;
    padding: 6px 8px;
  }
`;

export const CreditWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 32px;
  align-items: start;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

export const CustomeTextInput = styled(CustomeTextfield)`
  & input {
    font-size: 12px;
    padding: 10px;
  }

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const BackOutlineButton = styled(OutlineButton)`
  color: var(--connectmenu-text-color) !important;
`;
export const SubmitFillButton = styled(FillButton)`
  color: var(--textinput-background-color) !important;
`;
