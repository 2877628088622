import styled from "styled-components";

export const TableContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  // justify-content: center;
  width: auto;
  height: 56px;

  background: var(--textinput-border-color);
  border-radius: 8px;
`;

interface TableHeadProps {
  isBulkHead?: boolean;
}
export const TableHead = styled.div<TableHeadProps>`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Font/White */

  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  gap: 8px;
  justify-content: start;
  width: ${(props) => (props.isBulkHead ? "326px" : "380.5px")};
  height: auto;
`;
export const TableBody = styled.div``;
export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 24px;

  gap: 8px;
  width: auto;
  height: 56px;

  background: var(--table-row-background-color);
  border-radius: 8px;
  margin-top: 0.5rem;
  transition: all ease 0.3s;
  :hover {
    cursor: pointer;
    transform: scale(1.01);
    // border: 1px solid #4d657e;
  }
`;
export const TableData = styled.div<TableHeadProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 0px;
  gap: 8px;
  justify-content: start;
  width: ${(props) => (props.isBulkHead ? "326px" : "380.5px")};
  justify-content: ${(props) => (props.isBulkHead ? "center" : "start")};

  height: 40px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Grey/Light */

  color: var(--primary-subtext-color);
`;
