import React from "react";
import {
  CustomeTextInput,
  FieldWrapper,
  ImageWrapper,
  MultipleInputWrapper,
  TextField,
  TextFieldLabel,
} from "./admin.style";
import { CustomeTextfield } from "components/shared/Textfield";
import AddButton from "../../../assets/images/addLogo.png";
import CancelButton from "../../../assets/images/cancel.png";
import { Image } from "components/features/Features.style";

const CreditInputBox = ({
  index,
  boxIndex,
  isSameCredit,
  value,
  recipient,
  creditValue,
  handleAdd,
  handleRemove,
  onChange,
}) => {
  return (
    <>
      <MultipleInputWrapper key={boxIndex}>
        <FieldWrapper>
          <TextFieldLabel>Enter recipient wallet address</TextFieldLabel>
          <CustomeTextInput
            name="recipient"
            onChange={(e) => onChange(e)}
            value={recipient}
            placeholder="wallet address"
            style={{ marginTop: "10px" }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextFieldLabel>Enter Credit </TextFieldLabel>
          <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
            <TextField
              name="credit"
              type="number"
              onChange={(e) => onChange(e)}
              // value={isSameCredit ? creditValue : value}
              value={value}
              placeholder="credit"
              style={{ marginTop: "10px" }}
              disabled={isSameCredit}
            />
            <ImageWrapper>
              <Image src={AddButton} onClick={handleAdd} />
            </ImageWrapper>
            {index === 0 ? null : (
              <ImageWrapper>
                <Image src={CancelButton} onClick={handleRemove} />
              </ImageWrapper>
            )}
          </div>
        </FieldWrapper>
      </MultipleInputWrapper>
    </>
  );
};

export default CreditInputBox;
