import { Container } from "@mui/material";
import { FillButton } from "components/shared/Buttons";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
export const BackWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const BackContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  @media (${MediaBreakpoints.mobile}) {
    gap: 0px;
  }
`;
export const Title = styled.span`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  /* Font/Regular */

  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 25px;
  }
`;
export const DomainView = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Font/Subdued */

  color: var(--primary-subtext-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 12px;
  }
`;
export const BackIconWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  & > img {
    width: 30px;
    height: 30px;
    margin-top: 6px;
    @media (${MediaBreakpoints.mobile}) {
      width: 24px;
      height: 24px;
    }
    ${MediaBreakpoints.minidesk2} {
      width: 28px;
      height: 28px;
    }
    :hover {
      cursor: pointer;
    }
  }
`;
export const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2.8rem;
  @media (min-width: 601px) and (max-width: 765px) {
    gap: 10px;
  }

  & input {
    @media (${MediaBreakpoints.mobile}) {
      height: 19px !important;
      ::placeholder {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #4d657e;
      }
    }
  }
  & .filter {
    width: 114px !important;
    height: 56px !important;
    padding: 0px !important;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 600px) {
      width: 100% !important;
      height: 50px !important;
      font-size: 14px !important;
    }
    ${MediaBreakpoints.minidesk2} {
      height: 50px !important;
      width: 100px !important;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 12px;
    margin-top: 1rem;
  }
`;
export const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  & > button {
    width: 88px !important;
    height: 56px !important;
    padding: 0px !important;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (${MediaBreakpoints.mobile}) {
      height: 50px !important;
    }
    ${MediaBreakpoints.minidesk2} {
      height: 50px !important;
      width: 86px !important;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 7px;
  }
`;
export const AddModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 18px;

  width: 604px;
  height: auto;
  min-height:270px;
  ${MediaBreakpoints.ipad}{
    width:518px;
  }}
   ${MediaBreakpoints.minidesk}{
    width:518px;
  }}
   ${MediaBreakpoints.minidesk2}{
    width:518px;
  }}
  @media(${MediaBreakpoints.mobile}){
    width:100%;
    height:100%;
    position:relative;
    gap:25px;
  }
  ${MediaBreakpoints.minidesk2}{
    gap:15px;
    overflow:hidden;
  }
  & .add-record{
@media(${MediaBreakpoints.mobile}){
 
  bottom:0px;
  width:100%;
}
${MediaBreakpoints.minidesk2}{
   padding: 9px 19px;
   padding-bottom:9px !important;
   font-size:14px;
   margin-top:10px;
  }
  }
`;
export const AddModalContent2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 18px;

  width: 604px;
  height: auto;
  ${MediaBreakpoints.ipad}{
    width:518px;
  }}
   ${MediaBreakpoints.minidesk}{
    width:518px;
  }}
   ${MediaBreakpoints.minidesk2}{
    width:518px;
  }}
  @media(${MediaBreakpoints.mobile}){
    width:100%;
    height:100%;
    position:relative;
    gap:10px;
  }
  ${MediaBreakpoints.minidesk2}{
    gap:15px;
    
  }
  & .add-record{
@media(${MediaBreakpoints.mobile}){
  position:absolute;
  bottom:0px;
  width:100%;
}
${MediaBreakpoints.minidesk2}{
   padding: 9px 19px;
   padding-bottom:9px !important;
   font-size:14px;
   margin-top:10px;
  }
  }
`;
export const TextFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: auto;
  position: relative;
  ${MediaBreakpoints.minidesk2} {
    gap: 10px;
  }
  @media (${MediaBreakpoints.mobile}) {
    height: auto;
  }
  & input {
    @media (${MediaBreakpoints.mobile}) {
      height: 17px;
      ::placeholder {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* Font/Disabled */

        color: #4d657e;
      }
    }
  }
`;
export const TitleInput = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Font/Regular */

  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 14px;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  & > img {
    width: 24px;
    height: 24px;
    :active {
      transform: scale(0.9);
    }
  }
`;
export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 8px;

  width: auto;
  // height: 376px;
  margin-top: 0.5rem;
  background: var(--skeleton-background-color);
  border-radius: 8px;
  margin-bottom: 4rem;
  @media (${MediaBreakpoints.mobile}) {
    width: auto;
    height: auto;
    margin-bottom: 1rem;
  }
  ${MediaBreakpoints.minidesk2} {
    height: auto;
  }
`;
export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 32px 0px;

  width: 100%;
  // height: 360px;
  @media (${MediaBreakpoints.mobile}) {
    height: auto;
  }
  ${MediaBreakpoints.minidesk2} {
    height: auto;
  }
`;
export const FloderImage = styled.img`
  width: 100px;
  height: 100px;
  @media (${MediaBreakpoints.mobile}) {
    width: 48px;
    height: 48px;
  }
  ${MediaBreakpoints.minidesk2} {
    width: 90px;
    height: 90px;
  }
`;
export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 493px;
  height: 176px;
  ${MediaBreakpoints.minidesk2} {
    gap: 20px;
    height: auto;
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 16px;
    height: auto;
  }
  & .fill-button {
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 100px;
    }
  }
`;
export const EmptyTitle = styled.span`
  /* heading/2xl */

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Font/Regular */

  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 26px;
  }
`;
export const EmptyData = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  text-align: center;

  /* Grey/Light */

  color: var(--primary-subtext-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 18px;
  }
`;
export const SkeltonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const DeleteButton = styled(FillButton)`
  color: var(primary-text-color);
  background-color: var(--error-text-color);
  border: none;
`;

export const DeleteWrapper = styled.div`
  width: 668px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const Record = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-subtext-color);
  margin: 0;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    letter-spacing: 1.1px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  & button {
    @media (${MediaBreakpoints.mobile}) {
      font-size: 12px;
    }
  }
`;
export const TableScrollDiv = styled.div`
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  @media (width: 1400px) {
    height: 430px !important;
  }
  @media (width: 1680px) {
    height: 430px !important;
  }
  @media (min-width: 1920px) and (max-width: 2560px) {
    height: 430px;
  }
  ${MediaBreakpoints.minidesk2} {
    max-height: 209px;
    height: auto;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transperent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(77, 101, 126, 0.54);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(77, 101, 126, 0.54);
  }
`;
export const ManageContainer = styled(Container)`
  @media (${MediaBreakpoints.mobile}) {
    padding: 5px !important;
  }
  ${MediaBreakpoints.ipad} {
    padding: 5px !important;
  }
  ${MediaBreakpoints.minidesk} {
    padding: 5px !important;
  }
  ${MediaBreakpoints.minidesk2} {
    padding: 5px !important;
    padding-top: 0px !important;
  }
`;
export const FilterContent = styled.div`
  width: 72vw;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const RecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;

  width: auto;
  height: 184px;

  background: var(--table-row-background-color);
  border-radius: 16px;
`;
export const RecordContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 100%;
`;
export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const CardTitle = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Font/Regular */

  color: var(--primary-text-color);
`;
export const CardContent = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;

  /* Grey/Light */

  color: var(--primary-subtext-color);
`;
export const CardAction = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  & button {
    width: 100%;
  }
`;
export const MobileBorder = styled.div`
  height: 1px;

  /* Font/Disabled */
  width: 100%;
  background: #4d657e;
  margin-top: 1rem;
  margin-bottom: 1rem;
  ${MediaBreakpoints.ipad} {
    margin-top: 2rem;
  }
`;
export const RecordCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;
export const TableDataTooltip = styled(Tippy)`
  background: #222d49 !important;
  color: #ffffff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  word-break: break-all;
  letter-spacing: 1px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  fex-d: c;
  flex-direction: column;
  gap: 2px;
  & .MuiFormControl-root {
    background-color: #192b49;
  }
  & span {
    font-size: 15px;
  }
`;
