import React from "react";
import { ButtonText, ConnectDevider, GoogleButton } from "./walletModal.style";
import Google from "../../assets/images/google.png";
import RazorPay from "../../assets/images/razorpay.png";
const WalletSocialLogin = () => {
  return (
    <>
      {/* <ConnectDevider>Or</ConnectDevider>
      <GoogleButton>
        <img src={Google} style={{ marginRight: "10px" }} />
        <ButtonText>Continue with Google</ButtonText>
      </GoogleButton>
      <GoogleButton>
        <img src={RazorPay} style={{ marginRight: "10px" }} />
        <ButtonText>Continue with Razorpay</ButtonText>
      </GoogleButton> */}
    </>
  );
};

export default WalletSocialLogin;
