import CopyRight from "components/footer/copyRight";
import Header from "components/header";
import React from "react";
import { HeaderWrapperMain, MainWrapp, Root } from "./contentwrapper.style";

const MainWrapper = ({ children }) => {
  return (
    <Root>
      <HeaderWrapperMain>
        <Header />
        <MainWrapp>{children}</MainWrapp>
      </HeaderWrapperMain>

      <CopyRight />
    </Root>
  );
};
export default MainWrapper;
