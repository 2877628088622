import styled from "styled-components";
import Snackbar from "@mui/material/Snackbar";

export const CustomeSnackBar = styled(Snackbar)`
  & .MuiAlert-filledSuccess {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01071em;
    background-color: #a9fdaf;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #44a55a;
    width: 280px;
    border: 1px solid #44a55a;
    margin-top: 5rem;
    box-shadow: 6px 6px 14px rgb(68 165 90 / 25%);
    border-radius: 8px;
    z-index: -11111;
    @media (max-width: 430px) {
      margin-top: 4rem;
    }
  }

  & .MuiAlert-filledWarning {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fef5eb;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #ff9039;
    width: 100%;
    border: 1px solid #ff9039;
    margin-top: 4rem;
    box-shadow: 6px 6px 14px rgb(255 144 57 / 15%);
    border-radius: 15px;
    z-index: -11;
  }
  & .MuiAlert-filledInfo {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #eef2fb;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #3a62b1;
    width: 100%;
    border: 1px solid #3a62b1;
    margin-top: 5rem;
    box-shadow: 6px 6px 14px rgba(58, 98, 177, 0.15);
    border-radius: 15px;
    z-index: -11;
  }
  & .MuiAlert-filledError {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01071em;
    background-color: #fbbbab;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 16px;
    color: #eb0f0f;
    width: 280px;
    border: 1px solid #ed0909;
    margin-top: 5rem;
    box-shadow: 6px 6px 14px rgb(165 68 68 / 25%);
    border-radius: 8px;
    z-index: -11;
    @media (max-width: 430px) {
      margin-top: 3rem;
    }
  }
`;
export const MessageText = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #087158;
`;

export const ErrorMessage = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #9c1637;
`;
export const ErrorText = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const InfoMessage = styled(ErrorMessage)`
  color: #3538cd; ;
`;

export const InfoText = styled(ErrorText)`
  color: #192b49;
`;

export const SuccessText = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #192b49;
`;
