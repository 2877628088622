import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";
import { Divider } from "@mui/material";

export const WalletWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 438px;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const Wallets = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--wallet-border-color);
  border-radius: 16px;
  // transition: 0.2s;
  transition: ease-out 0.5s;
  padding: 16px;
  // width: 100%;
  width: 290px;
  :active {
    transform: scale(0.9);
  }
  :hover {
    background-color: var(--wallet-hover-background-color);
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const WalletName = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 1.2px;
    /* Font/Regular */

    color: var(--primary-text-color);
  }
`;

export const WalletLogo = styled.div`
  width: 46px;
  height: 46px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ConnectDevider = styled(Divider)`
  width: 98%;
  :before {
    border-top: 1px solid #90a7c0 !important;
  }
  :after {
    border-top: 1px solid #90a7c0 !important;
  }
`;

export const GoogleButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease-out 0.5s;
  :active {
    transform: scale(0.9);
  }

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.54);
`;

export const WalletText = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-text-color);
  margin-top: 0;
`;

export const ButtonText = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: var(--social-text-color);
  margin: 0;
`;
