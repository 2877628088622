import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";

export const AnimateButton = styled.button`
  position: relative;
  display: inline-block;
  border-radius: 100px;
  padding-bottom: 14px !important;
  padding: 12px 24px;
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--connectmenu-text-color);
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid var(--connectbutton-color);

  box-shadow: inset 0 0 0 0 var(--connectbutton-color);
  :hover {
    color: var(--conectmenu-hover-text-color);
    box-shadow: inset 0 -100px 0 0 var(--connectbutton-color);
  }
  :active {
    transform: scale(0.9);
  }
`;

export const OutlineButton = styled.button`
  position: relative;
  display: inline-block;
  border-radius: 100px;
  padding-bottom: 14px;
  padding: 12px 24px;
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--outline-button-color);
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid var(--outline-button-color);

  box-shadow: inset 0 0 0 0 var(--outline-button-color);

  :active {
    transform: scale(0.9);
  }
`;

export const MobileDeleteButton = styled(OutlineButton)`
  color: var(--disconnect-text-color);
  border: 1px solid var(--disconnect-text-color);
`;

export const OutlineMiniButton = styled.button`
  position: relative;
  display: inline-block;
  border-radius: 100px;
  padding: 8px 16px;
  width: 157px;
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: var(--button-background-color);
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid var(--button-background-color);

  box-shadow: inset 0 0 0 0 #81ecdf;

  :active {
    transform: scale(0.9);
  }
`;

interface FillButtonProps {
  disabled?: boolean;
}

export const FillButton = styled.button<FillButtonProps>`
  position: relative;
  display: inline-block;
  border-radius: 100px;
  padding-bottom: 14px;
  padding: 12px 24px;
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  // color: #030711;
  color: ${(props) =>
    props.disabled ? "#4D657E" : "var(--button-label-color)"};
  // background: #81ecdf;
  background-color: ${(props) =>
    props.disabled ? "#90A7C0" : "var(--button-background-color)"};

  cursor: pointer;
  transition: ease-out 0.5s;
  border: ${(props) =>
    props.disabled
      ? "2px solid #90A7C0"
      : "2px solid var(--button-background-color)"};

  box-shadow: inset 0 0 0 0 #81ecdf;

  :active {
    transform: scale(0.9);
  }
`;
export const FillSmallButton = styled.button`
  position: relative;
  display: inline-block;
  border-radius: 100px;
  padding-bottom: 10px !important;
  padding: 8px 16px;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--button-label-color);
  background: var(--button-background-color);
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid var(--button-background-color);

  box-shadow: inset 0 0 0 0 var(--button-background-color);

  :active {
    transform: scale(0.9);
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 13px;
  }
`;
export const ErrorOutlineButton = styled.button`
  position: relative;
  display: inline-block;
  border-radius: 100px;
  padding-bottom: 14px !important;
  padding: 12px 24px;
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--error-text-color);
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid var(--error-text-color);

  box-shadow: inset 0 0 0 0 var(--error-text-color);

  :active {
    transform: scale(0.9);
  }
`;
