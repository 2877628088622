import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  CustomeSnackBar,
  ErrorMessage,
  ErrorText,
  MessageText,
  SuccessText,
} from "./Snackbar.style";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface isProps {
  // Open Snackbar
  open: boolean;
  //Close Snackbar
  handleClose: () => void;
  //Success message
  Message: string;
  //Text message
  Text: string;
  isInfo?: boolean;
}
const Error = ({ open, handleClose, Message, Text, isInfo }: isProps) => {
  return (
    <CustomeSnackBar
      open={open}
      autoHideDuration={1000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      // isInfo={isInfo}
    >
      <Alert
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%", textAlign: "left" }}
      >
        <ErrorMessage>{Message}</ErrorMessage>
        <ErrorText>{Text}</ErrorText>
      </Alert>
    </CustomeSnackBar>
  );
};
export default Error;
