import { TextWrapper } from "components/manageDns/managedns.style";
import { CustomeTextfield } from "components/shared/Textfield";
import React, { useEffect, useState } from "react";
import "../../../App.css";
import AddButton from "../../../assets/images/addLogo.png";
import CancelButton from "../../../assets/images/cancel.png";
import {
  BackOutlineButton,
  CenterWrapper,
  CreditWrapper,
  DualButtonWrapper,
  FieldWrapper,
  Heading,
  ImageWrapper,
  MultipleCreditInpurtWrapper,
  MultipleInputWrapper,
  ParentMultipleInputWrapper,
  ScrollWrapper,
  SubmitFillButton,
  TextField,
  TextFieldLabel,
  UserInput,
} from "./admin.style";
import { FillButton, OutlineButton } from "components/shared/Buttons";
import { Image } from "components/features/Features.style";
import CreditInputBox from "./inputBox";
import { ButtonWrapper } from "components/searchList/CheckoutModal.style";
import { ScrollDiv } from "components/myProfile/myprofile.style";
import { CustomCheckbox } from "components/shared/Checkbox";
import { PersonilizeContainer } from "components/myProfile/personalize.style";
import { useMobile } from "mediaQuerys/useMobile";
import { BigNumber } from "@ethersproject/bignumber";
import { getContract } from "utilities/getContract";
import {
  dappSupportedNetworks,
  dappsDnsNewAddress,
} from "constants/dappsContract";
import dappsDnsNewABI from "abi/DappsDnsNew";
import { useWeb3React } from "@web3-react/core";
import { ZeroAddress, isAddress } from "ethers";
import { LoaderClip } from "components/loaders/Cliploader";
import Success from "components/shared/Snackbar/Success";
import Error from "components/shared/Snackbar/Error";
// interface inputProps {
//   walleAdd?: string;
//   credits: number;
// }

const MultipleCreditSystem = ({ handleBack }) => {
  const [inputFields, setInputFields] = useState<any>(1);
  const [isSameCredit, setIsSameCredit] = useState<boolean>(false);
  const [creditValue, setCreditValue] = useState<string | undefined>();
  const mobile = useMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [snackbarHeader, setSnackbarHeader] = useState("");
  const [snackbarText, setSnackbarText] = useState("");
  const [creditInputComponent, setCreditInputComponent] = useState([
    {
      id: Math.floor(Math.random() * 1000),
      recipient: "",
      credit: creditValue,
    },
  ]);
  const { account, chainId, library } = useWeb3React();
  const supportedChain = dappSupportedNetworks[0];

  const handleInputBox = (e) => {
    const length = parseInt(e.target.value);
    if (length > 50) {
      // alert("max 50 users allowed");
      setSnackbarHeader("Validation Error");
      setSnackbarText("max 50 users allowed");
      setIsError(true);
      return;
    } else if (length < 1) {
      // alert("at least 1 user should be selected");
      setSnackbarHeader("Validation Error");
      setSnackbarText("at least 1 user should be selected");
      setIsError(true);
      return;
    }
    setInputFields(length);
    setCreditInputComponent(
      Array.from({ length }, (_, index) => ({
        id: Math.floor(Math.random() * 1000),
        recipient: "",
        credit: creditValue,
      }))
    );
  };

  const addInputBox = () => {
    setCreditInputComponent([
      ...creditInputComponent,
      {
        id: Math.floor(Math.random() * 1000),
        recipient: "",
        credit: "",
      },
    ]);
    setInputFields((prev) => prev + 1);
  };

  const deleteInputBox = (index) => {
    const filterBox = creditInputComponent.filter((box) => {
      return box.id !== index;
    });
    setCreditInputComponent(filterBox);
    setInputFields((prev) => prev - 1);
  };

  const handleChange = (e, index: number) => {
    const { name, value } = e.target;
    console.log(name, index);
    const creditObj = [...creditInputComponent];
    creditObj[index][name] = value;
    if (name === "credit") {
      if (!value) creditObj[index][name] = "";
      else if (BigNumber.from(value).lt(0)) creditObj[index][name] = "0";
    }
    setCreditInputComponent(creditObj);
  };

  const handleCredit = (e) => {
    const credValue = e.target.value;
    if (!credValue) setCreditValue("");
    else if (BigNumber.from(credValue).lt(0)) setCreditValue("0");
    else setCreditValue(credValue);
  };

  const handleCheckbox = (e) => {
    setIsSameCredit(e.target.checked);
  };

  const addCreditsInBatch = async () => {
    try {
      // validation for same credits
      if (
        isSameCredit &&
        (!creditValue || BigNumber.from(creditValue).lte(0))
      ) {
        // alert("Invalid credit value");
        setSnackbarHeader("Validation Error");
        setSnackbarText("Invalid credit value");
        setIsError(true);
        return;
      }
      const recipientList = [],
        creditList = [];
      for (let index = 0; index < creditInputComponent.length; index++) {
        const element = creditInputComponent[index];
        console.log(element.recipient !== "");
        if (
          element.recipient === "" ||
          !isAddress(element.recipient) ||
          element.recipient === ZeroAddress
        ) {
          // alert(`Invalid recipient address: ${element.recipient}`);
          setSnackbarHeader("Validation Error");
          setSnackbarText(`Invalid recipient address: ${element.recipient}`);
          setIsError(true);
          return;
        }
        if (
          !isSameCredit &&
          (!element.credit || BigNumber.from(element.credit).lte(0))
        ) {
          // alert(`Invalid credit value for recipient ${element.recipient}`);
          setSnackbarHeader("Validation Error");
          setSnackbarText(
            `Invalid credit value for recipient ${element.recipient}`
          );
          setIsError(true);
          return;
        }
        recipientList.push(element.recipient);
        if (isSameCredit) creditList.push(creditValue);
        else creditList.push(element.credit);
      }

      setIsLoading(true);
      const dappDnsNewInstance = getContract(
        dappsDnsNewAddress(supportedChain),
        dappsDnsNewABI.abi,
        library,
        account
      );
      console.log("rec: ", recipientList, creditList);
      const tx = await dappDnsNewInstance.addCreditsInBatch(
        recipientList,
        creditList
      );
      await tx.wait();
      setSnackbarHeader("Transaction successful");
      setSnackbarText("Credits sent successfully to all the selected users");
      setSuccess(true);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 3000)
    } catch (error) {
      console.log("Error in addCreditsInBatch: ", error);
      setSnackbarHeader("Transaction failed");
      setSnackbarText("Transaction failed due to some error");
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <>
      <PersonilizeContainer>
        <ParentMultipleInputWrapper className="align-center">
          <CenterWrapper>
            <Heading>Credit System</Heading>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomCheckbox onChange={handleCheckbox} />
              <TextFieldLabel>same credit to all</TextFieldLabel>
            </div>
          </CenterWrapper>
          <MultipleInputWrapper></MultipleInputWrapper>

          <CreditWrapper>
            <MultipleCreditInpurtWrapper>
              <TextFieldLabel>Enter no.of users </TextFieldLabel>

              <UserInput
                type="number"
                placeholder="enter number"
                onChange={handleInputBox}
                value={inputFields}
                className="no-spinner"

                // style={{ marginTop: "10px" }}
              />
            </MultipleCreditInpurtWrapper>
            {isSameCredit && (
              <MultipleCreditInpurtWrapper>
                <TextFieldLabel>Enter Credit </TextFieldLabel>

                <UserInput
                  type="number"
                  onChange={handleCredit}
                  value={creditValue}
                  className="no-spinner"
                  style={{ width: "200px" }}
                />
              </MultipleCreditInpurtWrapper>
            )}
          </CreditWrapper>
          <ScrollWrapper>
            {creditInputComponent?.map((box, ind) => {
              // console.log(ind);
              return (
                <CreditInputBox
                  onChange={(e) => handleChange(e, ind)}
                  index={ind}
                  isSameCredit={isSameCredit}
                  boxIndex={box.id}
                  value={box.credit}
                  recipient={box.recipient}
                  creditValue={creditValue}
                  handleAdd={addInputBox}
                  handleRemove={() => deleteInputBox(box.id)}
                />
              );
            })}
          </ScrollWrapper>

          <DualButtonWrapper>
            <BackOutlineButton onClick={handleBack}>Go Back</BackOutlineButton>
            {isLoading ? (
              <SubmitFillButton>
                <LoaderClip color="var(--primary-text-color)" size={16} />{" "}
                &nbsp;&nbsp;Submit
              </SubmitFillButton>
            ) : (
              <SubmitFillButton
                onClick={addCreditsInBatch}
                disabled={creditInputComponent.length === 0}
              >
                Submit
              </SubmitFillButton>
            )}
          </DualButtonWrapper>
        </ParentMultipleInputWrapper>
        <Success
          open={isSuccess}
          handleClose={() => setSuccess(false)}
          Message={snackbarHeader}
          Text={snackbarText}
        />
        <Error
          open={isError}
          handleClose={() => setIsError(false)}
          Message={snackbarHeader}
          Text={snackbarText}
        />
      </PersonilizeContainer>
    </>
  );
};

export default MultipleCreditSystem;
