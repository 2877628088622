import "../../App.css";
import {
  FaqAccordian,
  FaqDataWrapper,
  FaqDescTypography,
  FaqSummary,
  FaqTitle,
  FaqTitleTypography,
  FaqWrapper,
} from "./faq.style";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import FaqData from "./faq.json";
import { ReactComponent as Arrow } from "assets/images/backArrow.svg";

export const FAQ = () => {
  return (
    <>
      <FaqWrapper>
        <FaqTitle>Frequently Asked Questions</FaqTitle>
        <FaqDataWrapper>
          {FaqData.map((data, ind) => {
            return (
              <FaqAccordian
                key={ind}
                defaultExpanded={ind === 0 ? true : false}
              >
                <FaqSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: "var(--primary-text-color)" }}
                    />
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <FaqTitleTypography>{data.title}</FaqTitleTypography>
                </FaqSummary>
                <AccordionDetails>
                  <FaqDescTypography>{data.description}</FaqDescTypography>
                </AccordionDetails>
              </FaqAccordian>
            );
          })}
        </FaqDataWrapper>
      </FaqWrapper>
    </>
  );
};
