import React from "react";
import { CartCounter, CartHeaderWrapper, CartHeading } from "./Cart.style";
import { ReactComponent as Arrow } from "assets/images/backArrow.svg";
import { useNavigate } from "react-router-dom";

const CartHeader = ({ listLength }: any) => {
  const navigate = useNavigate();
  return (
    <CartHeaderWrapper>
      <Arrow onClick={() => navigate("/bulk")} style={{ cursor: "pointer" }} />
      <CartHeading>My Cart</CartHeading>
      <CartCounter>{listLength}</CartCounter>
    </CartHeaderWrapper>
  );
};

export default CartHeader;
