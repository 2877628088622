import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";
export const MainWrapp = styled.div`
height: 100%;
display: flex;
align-items: start;
justify-content: center;
@media(${MediaBreakpoints.mobile}){
    height: unset;
}
`;
export const Root=styled.div`
display: flex;
justify-content:space-between;
align-items:space-between;
flex-direction:column;
height:100vh;
gap:1rem;
@media(${MediaBreakpoints.mobile}){
    gap:1.1rem;
}
${MediaBreakpoints.ipad}{
    gap:1.5rem;
}
${MediaBreakpoints.minidesk2}{
    gap:2rem;
}
`
export const HeaderWrapperMain=styled.div`
display: flex;

flex-direction:column;
gap:4rem;
@media(${MediaBreakpoints.mobile}){
    gap:2rem;
}
${MediaBreakpoints.minidesk2}{
    gap:1rem;
}
`

