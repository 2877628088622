import CustomizedDialogs from "components/shared/Modal";
import React, { useState } from "react";
import VerifyModal from "./verifyModal";
import CreditSystem from "./creditSystem";
import MultipleCreditSystem from "./multipleCreditSystem";

const Admin = () => {
  const [isMultiple, setMultiple] = useState(false);

  return (
    <>
      {isMultiple ? (
        <MultipleCreditSystem handleBack={() => setMultiple(false)} />
      ) : (
        <CreditSystem handleMultiple={() => setMultiple(true)} />
      )}
    </>
  );
};

export default Admin;
