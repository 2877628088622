// import Logo from "assets/images/logo.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Container } from "@mui/material";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import Hamburger from "assets/images/hamburger.png";
import NFT from "assets/images/nft.png";
import DarkTheme from "assets/images/darkThemeLogo.png";
import lightTheme from "assets/images/Sun.png";
import BetaLogo from "assets/images/souldappsbeta.png";
import axios from "axios";
import Credit from "assets/images/credit.png";
import FeaturesModal from "components/features/Features";
import { useDisconnect } from "components/hook/useWeb3EagerConnect";
import { AnimateButton, OutlineButton } from "components/shared/Buttons";
import CustomizedMenus from "components/shared/Menu";
import { ReactComponent as Menusvg } from "assets/images/svgImages/humburger.svg";
import { ReactComponent as DappsLogo } from "assets/images/svgImages/dLogo.svg";
import {
  AccountNft,
  CreditText,
  CustomMenuItem,
  MainMenuList,
  NFTLogo,
} from "components/shared/Menu/menu.style";
import CustomizedDialogs from "components/shared/Modal";
import MobileModal from "components/shared/Modal/MobileModal";
import Success from "components/shared/Snackbar/Success";
import {
  Logo,
  WalletText,
  WalletWrapper,
} from "components/wallet/walletModal.style";
import { useIpad, useMobile } from "mediaQuerys/useMobile";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatWithoutRounding, miniDomain } from "utilities";
import { getDBGraphqlClient } from "utilities/graphql";
import { DOMAINS_BY_ACCOUNT } from "utilities/graphql/query";
import SoulContext from "../../context/context";
import { WalletItem, wallets } from "../wallet/WalletModal";

import { ReactComponent as DappsLogosvg } from "assets/images/svgImages/dappLogo.svg";
import { ReactComponent as MobileDappsLogosvg } from "assets/images/svgImages/mobileLogo.svg";
import {
  HeaderLinkWrap,
  HeaderText,
  HeaderWrap,
  LogoWrap,
  MobileLogoWrapper,
} from "./header.style";
import MobileDrawer from "./mobileDrawer";
import WalletSocialLogin from "components/wallet/walletSocialLogin";
import { WalletDivWrapper } from "components/walletConnect/walletConnect.style";
import { getContract } from "utilities/getContract";
import {
  dappSupportedNetworks,
  dappsDnsNewAddress,
} from "constants/dappsContract";
import dappsDnsNewABI from "abi/DappsDnsNew";
import { getJsonProvider } from "utilities/provider";

// import SearchIcon from "assets/images/search_header.png";
const Header = () => {
  const { active, account, activate, chainId } = useWeb3React();
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [openFeatureModal, setOpenFeatureModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMenu, setMenu] = useState(false);
  const location = useLocation();
  const Ipad = useIpad();
  const { pathname } = location;
  const mobile = useMobile();
  const [, setActiveConnector] = useState<AbstractConnector | null>(null);
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false);
  const navigate = useNavigate();
  const disconnect = useDisconnect();

  const { domainData, setDomainData, setMerkleProof, setTheme } =
    useContext(SoulContext);
  const Theme = useContext(SoulContext);

  const { theme, handleTheme } = Theme;

  // const [domainData, setDomainData] = useState({
  //   name: "",
  //   nftUrl: "",
  // });
  const [errorState, setErrorState] = useState({
    error: false,
    errorMessage: "",
  });
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const supportedNetwork = dappSupportedNetworks[0];
  const [credits, setCredits] = useState("0");

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // console.log(offset);
  useEffect(() => {
    async function checkDomain() {
      setDomainData({
        name: "",
        nftUrl: "",
      });

      try {
        const client = getDBGraphqlClient();

        const res = await client.query({
          query: DOMAINS_BY_ACCOUNT,
          variables: {
            useraddress: account,
          },
        });
        let regDomains = res.data.domains;
        console.log("domains: ", regDomains);

        if (regDomains?.length > 0) {
          const domain = regDomains[0];
          const ipfsData = await axios.get(domain.metadataurl, {
            headers: {
              Accept: "text/plain",
            },
          });
          console.log("ipfsData: ", ipfsData.data);
          // const ipfsData = await axios.get(domain.metadataurl);
          // console.log("ipfsData: ", ipfsData);

          // const ipfsData = await axios({
          //   method: "get",
          //   url: domain.metadataurl,
          //   headers: {
          //     // pinata_api_key: `${process.env.REACT_APP_PINATA_API_KEY}`,
          //     // pinata_secret_api_key: `${process.env.REACT_APP_PINATA_API_SECRET}`,
          //     // "Content-Type": "application/json",
          //     'Accept': 'text/plain'
          //   },
          // });
          const obj = {
            name: domain.domain,
            nftUrl: ipfsData.data.fastImage,
          };
          setDomainData(obj);
        }
      } catch (error) {
        console.log("Error in checkDomain: ", error);
      }
    }

    async function getCredits() {
      try {
        const provider = getJsonProvider(supportedNetwork);
        const dappsDnsNewInstance = getContract(
          dappsDnsNewAddress(supportedNetwork),
          dappsDnsNewABI.abi,
          provider
        );
        const credits = await dappsDnsNewInstance.credits(account);
        // console.log("credits: ", credits);
        setCredits(credits);
      } catch (error) {
        console.log("Error in getCredits: ", error);
      }
    }

    async function getMerkleProof() {
      try {
        setMerkleProof({ hasLatestData: false, data: null });
        const res = await axios.get(
          `https://stagingapi.dapps.co/merkle/accountProof/${account}`
        );
        console.log("merkle: ", res.data);
        setMerkleProof({ hasLatestData: true, data: res.data.data });
      } catch (error) {
        console.log("Error in getMerkleProof: ", error);
      }
    }

    if (account) {
      checkDomain();
      getCredits();
      getMerkleProof();
    }
  }, [account]);

  const handleConnectWallet = () => {
    setOpenWalletModal(true);
    setOpen(false);
  };

  const handleFeatureModal = () => {
    setOpenFeatureModal(true);
    setOpen(false);
  };
  const handleAbout = () => {
    navigate("/about");
    setOpen(false);
  };

  const handleFAQ = () => {
    navigate("/faq");
    setOpen(false);
  };
  const handleProfile = () => {
    setMenu(false);
    if (location.pathname === "/partner/search") {
      setTimeout(() => {
        navigate(`/partner/my-profile${location.search}`);
      }, 500);
    } else {
      setTimeout(() => {
        navigate("/my-profile");
      }, 500);
    }
  };
  const handleDisconnect = () => {
    setMenu(false);
    disconnect();
    localStorage.removeItem("wallet");
  };
  const LogoClick = () => {
    if (location.pathname.includes("/partner")) {
      setTimeout(() => {
        navigate(`/partner`);
      }, 500);
    } else {
      navigate("/");
    }
  };
  const OpenMenu = () => {
    setMenu(true);
  };
  const CloseMenu = () => {
    setMenu(false);
  };
  const activation = async (
    connector: AbstractConnector | undefined,
    index
  ) => {
    try {
      console.log("started");
      if (
        connector instanceof InjectedConnector &&
        window.ethereum.isMetaMask
      ) {
        connector &&
          activate(connector, undefined, true)
            .then(() => {
              console.log("Connected to metamask");
              setActiveConnector(connector);
              setIsWalletConnected(true);
              setOpenWalletModal(false);
              localStorage.setItem("wallet", "1");
            })
            .catch((error) => {
              console.log(error, "error in metamask");
            });
      } else if (connector instanceof WalletConnectConnector) {
        console.log("walletconnect");
        connector &&
          activate(connector, undefined, true)
            .then(() => {
              setActiveConnector(connector);
              setIsWalletConnected(true);
              localStorage.setItem("wallet", "2");
            })
            .catch(() => {
              console.log("error in walletconnect");
            });
      }
    } catch (error) {
      console.log("Error in  activation: ", error);
    }
  };

  const OpenMobileDrawer = () => {
    setOpen(true);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // setTheme(event.target.checked);
  //   // setChecked(event.target.checked);
  // };
  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    setErrorState({
      error: false,
      errorMessage: "",
    });
  };
  const SearchDomain = (event: any) => {
    const regex = new RegExp(/[ `!@#$%^&*()_+={};':"|,.<>?~]/);
    if (searchValue.length < 3) {
      setErrorState({
        error: true,
        errorMessage: "Please enter at least 3 characters",
      });
    } else if (searchValue.length > 250) {
      setErrorState({
        error: true,
        errorMessage: "Please enter less than 250 characters",
      });
    } else if (regex.test(searchValue)) {
      setErrorState({
        error: true,
        errorMessage: "Special characters except (“-”) are not allowed",
      });
    } else {
      if (event.key === "Enter") {
        navigate(`/search?search=${searchValue}`);
      }
    }
  };
  const SearchDomainClick = (event: any) => {
    const regex = new RegExp(/[ `!@#$%^&*()_+={};':"|,.<>?~]/);
    if (searchValue.length < 3) {
      setErrorState({
        error: true,
        errorMessage: "Please enter at least 3 characters",
      });
    } else if (searchValue.length > 250) {
      setErrorState({
        error: true,
        errorMessage: "Please enter less than 250 characters",
      });
    } else if (regex.test(searchValue)) {
      setErrorState({
        error: true,
        errorMessage: "Special characters except (“-”) are not allowed",
      });
    } else {
      navigate(`/search?search=${searchValue}`);
    }
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      {/* {theme.toString()}
      <Switch
        checked={theme}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      /> */}
      <HeaderWrap>
        {mobile ? (
          <MobileLogoWrapper isConnected={active && account ? true : false}>
            {/* <img
              src={Hamburger}
              alt="logo"
              width="24px"
              height="24px"
              className="hamburger"
              onClick={OpenMobileDrawer}
            /> */}
            <Menusvg className="hamburger" onClick={OpenMobileDrawer} />
            <MobileDappsLogosvg
              onClick={LogoClick}
              style={{ cursor: "pointer" }}
            />
            {/* <img
              src={BetaLogo}
              onClick={LogoClick}
              width="87.52px"
              alt="logo"
              height="32px"
            /> */}
            {/* <ConnetWrapper>NFT</ConnetWrapper> */}
            {active && account && (
              <OutlineButton onClick={OpenMenu}>
                <AccountNft>
                  <Logo
                    src={domainData.nftUrl ? domainData.nftUrl : NFT}
                    alt="logo"
                    style={{ borderRadius: 100 }}
                  />
                </AccountNft>
              </OutlineButton>
            )}
          </MobileLogoWrapper>
        ) : (
          <HeaderLinkWrap style={{ gap: Ipad ? 25 : 64 }}>
            <DappsLogosvg onClick={LogoClick} style={{ cursor: "pointer" }} />

            {/* <LogoWrap onClick={LogoClick}>
              <img src={BetaLogo} width="100%" alt="logo" />
            </LogoWrap> */}
            <HeaderText onClick={handleAbout}>About Dapps</HeaderText>
            <HeaderText onClick={handleFeatureModal}>Features</HeaderText>
            <HeaderText onClick={handleFAQ}>FAQ</HeaderText>
          </HeaderLinkWrap>
        )}

        {pathname === "/my-profile" && !active && !account ? null : (
          <>
            {mobile ? null : (
              <HeaderLinkWrap>
                {/* {Ipad ? (
                  <SearchButton />
                ) : (
                  <SearchBox style={{display:'flex',flexDirection:'column'}}>
                    <SmallTextField
                      placeholder="Search domains"
                      autoComplete="off"
                      value={searchValue}
                      onChange={onChangeSearch}
                      onKeyDown={SearchDomain}
                      error={errorState?.error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              onClick={SearchDomainClick}

                              style={{ fontSize: 28, color: "#f7f7f7" }}
                              sx={{
                                ":hover": {
                                  cursor: "pointer",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errorState?.error && (
                      <TextError >{errorState?.errorMessage}</TextError>
                    )}
                  </SearchBox>
                )} */}

                <div style={{ width: 200 }}>
                  {active && account ? (
                    <CustomizedMenus
                      walletAddress={
                        domainData.name
                          ? miniDomain(domainData.name)
                          : miniDomain(account)
                      }
                      nftUrl={domainData.nftUrl ? domainData.nftUrl : NFT}
                      credits={credits}
                      // handleProfile={handleProfile}
                    />
                  ) : (
                    <AnimateButton onClick={handleConnectWallet}>
                      {active && account ? "0x828b" : "Connect Wallet"}
                    </AnimateButton>
                  )}
                </div>

                <div
                  onClick={() => {
                    handleTheme();
                  }}
                >
                  <img
                    alt=""
                    src={theme === "light-theme" ? lightTheme : DarkTheme}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </HeaderLinkWrap>
            )}
          </>
        )}
      </HeaderWrap>
      <CustomizedDialogs
        open={openWalletModal}
        onClose={() => {
          setOpenWalletModal(false);
        }}
        title="Connect Wallet"
      >
        <WalletText>Via Wallet</WalletText>
        <WalletDivWrapper>
          {Object.keys(wallets).map((wallet, ind) => {
            const provider = wallets[wallet];
            return (
              <WalletItem
                {...provider}
                key={provider.id}
                onClick={() => {
                  activation(provider.connector, provider.id);
                }}
              />
            );
          })}
          <WalletSocialLogin />
        </WalletDivWrapper>
      </CustomizedDialogs>
      <CustomizedDialogs
        open={openFeatureModal}
        onClose={() => {
          setOpenFeatureModal(false);
        }}
        title=""
        isHeader={true}
      >
        <FeaturesModal />
      </CustomizedDialogs>
      <Success
        open={isWalletConnected}
        handleClose={() => {
          setIsWalletConnected(false);
        }}
        Message="Wallet connected successfully"
        Text=""
      />
      {/* <Info
        open={true}
        handleClose={() => {
          console.log("Wallet");
        }}
        Text="Your information text"
        Message="Referral Link Copied"
      /> */}

      <MobileDrawer
        isOpen={open}
        setOpen={setOpen}
        handleAbout={handleAbout}
        hadleConnect={handleConnectWallet}
        featureClick={handleFeatureModal}
        faqClick={handleFAQ}
      />
      <MobileModal
        open={isMenu}
        onClose={CloseMenu}
        title={
          <MainMenuList>
            <NFTLogo>
              <Logo
                src={domainData.nftUrl ? domainData.nftUrl : NFT}
                alt="logo"
                style={{ borderRadius: 100 }}
              />
            </NFTLogo>
            <p style={{ margin: 0, fontSize: "20px" }}>
              {domainData.name
                ? miniDomain(domainData.name)
                : miniDomain(account)}
            </p>
          </MainMenuList>
        }
        isHeader={false}
      >
        <CustomMenuItem>
          <img
            src={Credit}
            style={{
              marginRight: "12px",
              marginLeft: "4px",
            }}
          />
          <CreditText>
            Store Credits :
            <span
              style={{ color: "var(--primary-text-color)", fontWeight: "700" }}
            >
              {formatWithoutRounding(credits, 2)}
            </span>{" "}
          </CreditText>
        </CustomMenuItem>

        <CustomMenuItem
          onClick={handleProfile}
          style={{ marginBottom: "0.5rem" }}
        >
          <AccountCircleIcon style={{ fontSize: 30 }} />
          &nbsp; My Profile
        </CustomMenuItem>
        <CustomMenuItem onClick={handleDisconnect} style={{ color: "#F38C7F" }}>
          <AccountBalanceWalletIcon
            style={{ color: "#F38C7F", fontSize: 30 }}
          />
          &nbsp; Disconnect
        </CustomMenuItem>
      </MobileModal>
    </Container>
  );
};
export default Header;
