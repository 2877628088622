import { BigNumber } from "@ethersproject/bignumber";
import { formatEther, formatUnits } from "@ethersproject/units";
import { PublicKey } from "@solana/web3.js";

export const miniWalletAddress = (address: string, chars = 4): string => {
  if (!address) return null;
  return `${address.substring(0, chars + 2)}...${address.substring(
    42 - chars
  )}`;
};
export const miniDomain = (address: string, chars = 4): string => {
  if (!address) return null;
  if (address.length > 15) {
    return `${address.substring(0, chars + 4)}...${address.slice(-4)}`;
  } else {
    return `${address.substring(0, chars + 12)}`;
  }
};
export const recordType = {
  1: "A",
  2: "CNAME",
  3: "MX",
  4: "TXT",
};

export const getDomainCharSet = (domain: string): string => {
  const domainName = domain.substring(0, domain.lastIndexOf("."));
  const letterRegex = /^[a-z]+$/i;
  const digitRegex = /^\d+$/;
  // ab-123, abc-a, 12-1, ---
  if (domainName.includes("-")) return "mixed";
  // abc
  else if (letterRegex.test(domainName)) return "letter";
  // 123
  else if (digitRegex.test(domainName)) return "digit";
  // abc123
  else if (/\d/.test(domainName) && /[a-z]/i.test(domainName))
    return "alphanumeric";
};

export const getDomainNameLength = (domain: string): number => {
  return domain.substring(0, domain.lastIndexOf(".")).length;
};

// console.log(getDomainCharSet("123"));
// console.log(getDomainNameLength("rajat1-a.5ire"));

export const WalletAddress = (address: string): string => {
  if (!address) return null;
  return address;
};

export const unitFormatter = (
  units: string | BigNumber,
  decimals: number = 18
): number => {
  if (!units) return null;
  return Number(formatUnits(units, decimals ? decimals : 18));
};

export const formatWithoutRounding = (
  value: string | BigNumber,
  decimals: number
): string => {
  // Convert Wei value to Ether value with two decimal places (without rounding off)
  const etherString = formatEther(value);
  const etherParts = etherString.split(".");
  let ethValue = etherParts[0];

  if (etherParts.length > 1) {
    const decimalPart = etherParts[1].substring(0, decimals);
    if (!BigNumber.from(decimalPart).isZero()) {
      // display the number with two decimal places
      ethValue += "." + decimalPart;
    }
  }
  return ethValue;
};

export const validateBTCaddress = (address: string) => {
  try {
    const regex = new RegExp(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,59}$/);

    // if str
    // is empty return false
    if (address == null) {
      return false;
    }

    // Return true if the str
    // matched the ReGex
    const tir=(regex.test(address));
    console.log(tir,"rirr")
    if (regex.test(address) === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error, "error in validating BTC address");
    return false;
  }
};

export const validateSolanaAddress = async (address: string) => {
  console.log(address);
  let publicKey: PublicKey;
  try {
    publicKey = new PublicKey(address);
    const isValid = PublicKey.isOnCurve(publicKey.toBytes());

    if (isValid) {
      return true;
    } else return false;
  } catch (err) {
    console.log(err, "error in validating solana address");
    return false;
  }
};

export const validateCardanoAddress = (address:string) => {
  if (address.startsWith("addr1")) {
    return true;
  } else {
    console.log('Address is not valid.');
    return false;
  }
};
