import { useWeb3React } from "@web3-react/core";
import dappsDnsNewABI from "abi/DappsDnsNew";
import Admin from "components/admin/creditSystem";
import {
  dappSupportedNetworks,
  dappsDnsNewAddress,
} from "constants/dappsContract";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getContract } from "utilities/getContract";

const AdminDashboard = () => {
  const { account, library } = useWeb3React();
  const supportedChain = dappSupportedNetworks[0];
  const navigate = useNavigate();

  useEffect(() => {
    async function checkOwner() {
      try {
        const dappDnsNewInstance = getContract(
          dappsDnsNewAddress(supportedChain),
          dappsDnsNewABI.abi,
          library,
          account
        );
        const owner = await dappDnsNewInstance.owner();
        if (owner !== account) navigate("/");
      } catch (error) {
        console.log("Error in in checkOwner: ", error);
      }
    }

    checkOwner();
  }, [account]);

  return (
    <>
      <Admin />
    </>
  );
};

export default AdminDashboard;
