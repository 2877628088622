import { AbstractConnector } from "@web3-react/abstract-connector";
import Metamask from "assets/images/metamask.png";
import Walletconnect from "assets/images/walletconnect.png";
import { injected, walletconnect } from "connectors";
import { Logo, WalletLogo, WalletName, Wallets } from "./walletModal.style";

interface Wallet {
  [connetorName: string]: {
    id: string;
    name: string;
    logo: string;
    connector: AbstractConnector;
    isMobileSupported: boolean;
    depiction: string;
  };
}

export const wallets: Wallet = {
  METAMASK: {
    id: "1",
    name: "Metamask",
    logo: Metamask,
    connector: injected,
    isMobileSupported: false,
    depiction: "Chrome Extension Trusted By 10 Million Users.",
  },
  WALLETCONNECT: {
    id: "2",
    name: "Wallet Connect",
    logo: Walletconnect,
    connector: walletconnect,
    isMobileSupported: true,
    depiction: "Using Trust Wallet & Rainbow Wallet",
  },
};

interface WalletModalProps {
  name?: string;
  logo?: string;
  onClick?: () => void;
}

export const WalletItem = ({ name, logo, onClick }: WalletModalProps) => {
  return (
    <>
      <Wallets onClick={onClick}>
        <WalletName>{name}</WalletName>
        <WalletLogo>
          <Logo src={logo} alt={name} />
        </WalletLogo>
      </Wallets>
    </>
  );
};
