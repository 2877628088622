import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";
import "../../index.css";
export const DomainSearchWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
`;
export const DomainTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${MediaBreakpoints.minidesk2} {
    gap: 28px;
  }
  @media (${MediaBreakpoints.mobile}) {
    gap: 16px;
  }
`;
export const TitleHead = styled.span`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 88px;
  /* or 138% */

  text-align: center;

  /* Font/Regular */

  // color: #f7f7f7;
  color: var(--primary-text-color);

  @media (max-width: 430px) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (min-width: 431px) and (max-width: 767px) {
    font-size: 7vw;
    line-height: 8.5vw;
  }
  ${MediaBreakpoints.ipad} {
    font-size: 64px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 47px;
    line-height: 57px;
  }
`;
export const TitleInfo = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Font/Subdued */

  // color: #90a7c0;
  color: var(--primary-subtext-color);
  @media (min-width: 431px) and (max-width: 767px) {
    font-size: 3vw;
    line-height: 20px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 24px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 18px;
  }
`;
export const FormWrapper = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: start;
  ${MediaBreakpoints.minidesk2} {
    gap: 18px !important;
  }
  & .in-search {
    flex-direction: column;
  }
  & .mobile-button {
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
      padding-bottom: 12px !important;
    }
    ${MediaBreakpoints.minidesk} {
      width: 20% !important;
      font-size: 12px !important;
    }
    ${MediaBreakpoints.ipad} {
      width: 35% !important;
    }
    ${MediaBreakpoints.minidesk2} {
      font-size: 14px !important;
    }
  }
  & button {
    padding: 16px;
    padding-bottom: 16px !important;
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
    }
    ${MediaBreakpoints.minidesk2} {
      font-size: 14px;
      padding: 13px;
      padding-bottom: 13px !important;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
  }
`;
export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;
export const TextError = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin-left: 23px;

  /* Font/Error */

  color: var(--error-text-color);
  margin-top: 0.2rem;
  letter-spacing: 1px;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 14px !important;
  }
`;
export const MainImageContainer=styled.div`
width: 150.07px;
height: 64px;
& img{
  width:100%;
  height:100%;
}
`