import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { MessageText, SuccessText } from "./Snackbar.style";
import { CustomeSnackBar } from "./Snackbar.style";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface isProps {
  // Open Snackbar
  open: boolean;
  //Close Snackbar
  handleClose: () => void;
  //Success message
  Message: string;
  //Text message
  Text: string | any;
  position?: boolean;
  isInfo?: boolean;
  isMargin?: boolean;
  isMargin2?: boolean;
}
const Success = ({
  open,
  handleClose,
  Message,
  Text,
  position,
  isInfo,
  isMargin,
  isMargin2,
}: isProps) => {
  return (
    <CustomeSnackBar
      open={open}
      autoHideDuration={1000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      //   position={position}
      //   isInfo={isInfo}
      //   style={{ marginTop: isMargin ? "3rem" : isMargin2 ? "4rem" : null }}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{ width: "100%", textAlign: "left" }}
      >
        <MessageText>{Message}</MessageText>

        <SuccessText>{Text}</SuccessText>
      </Alert>
    </CustomeSnackBar>
  );
};
export default Success;
