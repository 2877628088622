import { createSlice } from "@reduxjs/toolkit";

const FilterSlice = createSlice({
  name: "filterSlice",
  initialState: {
    recordTypes: {
      "1": false,
      "2": false,
      "3": false,
      "4": false,
    },
    // recordTypes: {
    //   typeA: false,
    //   typeCname: false,
    //   typeMx: false,
    //   typeTxt: false,
    // },
  },
  reducers: {
    handleFilter: (state, action) => {
      const { type, value } = action.payload;
      return {
        ...state,
        recordTypes: {
          ...state.recordTypes,
          [type]: value,
        },
      };
    },
  },
});

export const { handleFilter } = FilterSlice.actions;

export default FilterSlice.reducer;
