import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";

export const LinkRedirect = styled.a`
  text-decoration: none;
`;

export const CopyRightBar = styled.div`
  bottom: 0px;
  padding: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-subtext-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 463px;
  transition: 0.5s;

  height: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  :hover {
    cursor: pointer;
    color: var(--primary-text-color);
  }
`;

export const FooterSocialWrapper = styled.a`
  text-decoration: none;
  color: #192b49;

  width: 44px;
  height: 44px;
  // padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  background: #91b6d1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  &:hover {
    color: #f7f7f7;
  }

  @media (${MediaBreakpoints.mobile}) {
    width: 30px;
    height: 30px;
  }
  ${MediaBreakpoints.minidesk2} {
    width: 35px;
    height: 35px;
  }
  & svg {
    @media (${MediaBreakpoints.mobile}) {
      width: 0.8em;
      height: 0.8em;
    }
    ${MediaBreakpoints.minidesk2} {
      width: 0.8em;
      height: 0.8em;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;

  bottom: 0;
`;
export const MainWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  position: relative;

  height: 71px;
  display: flex;
  justify-content: space-between;
  // position: absolute;
  bottom: 0px;
  align-items: center;
  width: 96%;
  @media (${MediaBreakpoints.mobile}) {
    margin-top: 30px;
    height: auto;
    width: 100%;
    flex-direction: column-reverse;
    gap: 7px;
  }
`;
export const SocialWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  & svg {
    :hover {
      cursor: pointer;
    }
  }
  ${MediaBreakpoints.minidesk2} {
    gap: 15px;
  }
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 15px;
  }
`;
export const SocialIconWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  & svg {
    :hover {
      cursor: pointer;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    gap: 10px;
  }
  ${MediaBreakpoints.minidesk2} {
    gap: 15px;
  }
`;

export const SupportModalWrapper = styled.div`
  width: 604px;
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;
export const SupportItemsWrapper = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #4d657e;
  border-radius: 16px;
  padding: 16px;
  transition: all 0.5s;
  ${MediaBreakpoints.minidesk2} {
    padding: 14px;
  }
  :hover {
    background-color: #091430;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  :active {
    transform: scale(0.98);
  }
`;
export const SupportText = styled.span`
* body/md-700 */

font-family: 'Manrope';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #F7F7F7;
letter-spacing:1.3px;
@media (${MediaBreakpoints.mobile}) {
  font-size: 14px;
}
${MediaBreakpoints.minidesk2} {
  font-size: 15px;
}

`;
export const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  @media (${MediaBreakpoints.mobile}) {
    width: 32px;
    height: 32px;
  }
  ${MediaBreakpoints.minidesk2} {
    width: 40px;
    height: 40px;
  }
`;
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
