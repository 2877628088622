import React from "react";
import {
  ApplyBtn,
  CartDetailsWrapper,
  CartSummaryCard,
  CartSummeryHeading,
  Detailkey,
  Divider,
  ReferralInputWrapper,
  ReferralWrapper,
  RefferDomainTextField,
} from "./Cart.style";
import { FillButton } from "components/shared/Buttons";
import { miniWalletAddress } from "utilities";

const CartSummary = ({ walletAdd, orderTotal }) => {
  const walletAddress = miniWalletAddress(walletAdd);
  return (
    <>
      <CartSummaryCard>
        <CartSummeryHeading>Order Summary</CartSummeryHeading>
        <CartDetailsWrapper>
          <Detailkey>Wallet Address :</Detailkey>
          <Detailkey style={{ fontWeight: 700 }}>{walletAddress}</Detailkey>
        </CartDetailsWrapper>
        <Divider />

        <ReferralWrapper>
          <Detailkey>Enter referral domain (Optional)</Detailkey>
          <ReferralInputWrapper>
            <RefferDomainTextField placeholder="referral.5ire" />
            <ApplyBtn>Apply</ApplyBtn>
          </ReferralInputWrapper>
        </ReferralWrapper>
        <Divider />

        <CartDetailsWrapper>
          <Detailkey>Order Total :</Detailkey>
          <Detailkey>{orderTotal}</Detailkey>
        </CartDetailsWrapper>

        <FillButton>Checkout</FillButton>
      </CartSummaryCard>
    </>
  );
};

export default CartSummary;
