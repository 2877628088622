import {
  ImageContainer,
  SupportItemsWrapper,
  SupportText,
} from "./copyRight.style";
interface isProps {
  title: string;
  image: string;
}
export const SuportItems = ({ title, image }: isProps) => {
  return (
    <SupportItemsWrapper>
      <SupportText>{title}</SupportText>
      <ImageContainer><img src={image} width="100%" alt=""/></ImageContainer>
    </SupportItemsWrapper>
  );
};
