import React from "react";
import {
  CardContent,
  CardDetails,
  CardWrapper,
  CartHeading,
} from "./Cart.style";
import { MobileDeleteButton } from "components/shared/Buttons";

const DomainCard = ({ domainName, domainDuration, domainPrice }) => {
  return (
    <CardWrapper>
      <CardContent>
        <CardDetails>
          <CartHeading>Domain :</CartHeading>
          <CartHeading>{domainName}</CartHeading>
        </CardDetails>
        <CardDetails>
          <CartHeading>Duration :</CartHeading>
          <CartHeading style={{ fontWeight: 400 }}>
            {domainDuration}
          </CartHeading>
        </CardDetails>
        <CardDetails>
          <CartHeading>Price:</CartHeading>
          <CartHeading>{domainPrice}</CartHeading>
        </CardDetails>

        <MobileDeleteButton>Delete</MobileDeleteButton>
      </CardContent>
    </CardWrapper>
  );
};

export default DomainCard;
