import { FillButton, OutlineButton } from "components/shared/Buttons";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";

export const HeaderTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VisitWrapper = styled(HeaderTextWrapper)`
  gap: 10px;
`;

export const VisitDapps = styled.a`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  /* identical to box height, or 150% */

  text-decoration-line: underline;

  /* Brand/Light */

  color: var(--connectmenu-text-color);
`;

export const HeadText = styled.span`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 25px;
  }
`;
export const CustomizeBox = styled.div`
  display: flex;
  & .MuiButtonBase-root.Mui-selected {
    background: var(--accountmenu-border-color);
    border-radius: 8px;
    color: white;
    width: 207px;
    height: 56px;
    text-align: left;
    align-items: start;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    text-transform: capitalize;
    /* Font/Regular */

    color: var(--primary-text-color);
    @media (${MediaBreakpoints.mobile}) {
      font-size: 12px;
      width: 109px;
      letter-spacing: 1.5px;
      padding-right: 0px;
      padding-left: 0px;
      align-items: center;
    }
    ${MediaBreakpoints.minidesk2} {
      font-size: 16px;
    }
  }
  & .MuiButtonBase-root {
    color: white;
    width: 207px;
    height: 56px;

    text-align: left;
    align-items: start;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    text-transform: capitalize;
    /* Font/Regular */

    color: var(--primary-text-color);
    @media (${MediaBreakpoints.mobile}) {
      font-size: 12px;
      width: 109px;
      letter-spacing: 1.5px;
      padding-right: 0px;
      padding-left: 0px;
      align-items: center;
    }
    ${MediaBreakpoints.minidesk2} {
      font-size: 16px;
    }
  }
  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiTabs-flexContainer {
    gap: 17px;
    @media (${MediaBreakpoints.mobile}) {
      flex-direction: row;
      gap: 10px;
    }
    ${MediaBreakpoints.ipad} {
      flex-direction: row;
    }
    ${MediaBreakpoints.minidesk} {
      flex-direction: row;
    }
  }
`;
export const ContentWrap = styled.div`
  padding-top: 32px;
  display: flex;
  gap: 24px;
  height: auto;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    // height: 100%;
    gap: 15px;
  }
  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    height: 100%;
    gap: 15px;
  }
  ${MediaBreakpoints.minidesk} {
    flex-direction: column;
    height: 100%;
    gap: 15px;
  }
`;
export const TabPanel = styled.div``;
export const MyDomainWrap = styled.div`
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  & .scroll-div {
    max-height: 600px;
    height: auto;
    min-height: 600px;
    padding-bottom: 0px;
    @media (width: 1600px) {
      max-height: 445px !important;
      height: auto;
      min-height: 445px !important;
    }
    @media (min-width: 820px) and (max-width: 1180px) {
      max-height: 620px !important;
      height: auto;
      min-height: 620px !important;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      max-height: 580px;
      height: auto;
      min-height: 580px;
      padding-bottom: 0px;
    }
    ${MediaBreakpoints.minidesk2} {
      max-height: 320px;
      height: auto;
      min-height: 320px;
      padding-bottom: 0px;
    }
    @media (${MediaBreakpoints.mobile}) {
      max-height: fit-content;
      height: auto;
      min-height: auto;
      margin-bottom: 2rem;
    }

    @media (min-width: 1920px) and (max-width: 2560px) {
      max-height: 600px;
      height: auto;
      min-height: 600px;
      padding-bottom: 0px;
    }
  }
`;
export const DomainTitle = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Font/Regular */

  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
  }
`;
export const DomainCardWrap = styled.div`
  width: auto;
  height: auto;

  background: var(----offercard-background-color);
  /* Font/Disabled */
  transition: all ease 0.3s;
  border: 1px solid var(--textinput-border-color);
  padding: 40px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${MediaBreakpoints.minidesk2} {
    padding: 35px;
  }
  :hover {
    box-shadow: 0px 0px 6px 1px #4d657e;
    cursor: pointer;
    transform: scale(1.01);
    border: 1px solid #4d657e;
  }
  @media (${MediaBreakpoints.mobile}) {
    width: auto;
    flex-direction: column;
    align-items: start;
    padding: 16px;
    gap: 18px;
  }
  ${MediaBreakpoints.ipad} {
    width: auto;
  }
  ${MediaBreakpoints.minidesk} {
    width: auto;
  }
  ${MediaBreakpoints.minidesk2} {
    width: auto;
  }
  & .mint-button {
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
      justify-content: center;
    }
  }
`;
export const CardListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  @media (${MediaBreakpoints.mobile}) {
    padding: 0px;
  }
  @media (${MediaBreakpoints.ipad}) {
    padding: 4px;
  }
  @media (${MediaBreakpoints.minidesk}) {
    padding: 8px;
  }
  @media (${MediaBreakpoints.minidesk2}) {
    padding: 8px;
  }
`;
export const DomainText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: auto;
  height: auto;
  & > img {
    height: 32px;
    width: 32px;
    ${MediaBreakpoints.minidesk2} {
      height: 30px;
      width: 30px;
    }
    @media (${MediaBreakpoints.mobile}) {
      height: 30px;
      width: 30px;
      margin-top: 5px;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    gap: 0px;
    width: 100%;
  }
`;
interface isProps {
  isExclusive: boolean;
}
export const DomainName = styled.span<isProps>`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;
  /* Font/Regular */

  color: ${(props) =>
      props.isExclusive ? "#FDC465;" : "var(--primary-text-color);"}
    & > img {
    height: 32px;
    width: 32px;
    ${MediaBreakpoints.minidesk2} {
      height: 30px;
      width: 30px;
    }
    @media (${MediaBreakpoints.mobile}) {
      height: 30px;
      width: 30px;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
    justify-content: space-between;
    width: 100%;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 25px;
  }
`;
interface isProps2 {
  isExpired: boolean;
}
export const DomainValidity = styled.span<isProps2>`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Font/Subdued */

  color: ${(props) => (props.isExpired ? "#F38C7F;" : "#90a7c0;")} @media
    (${MediaBreakpoints.mobile}) {
    font-size: 12px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 15px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0px;
  gap: 16px;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  & > button {
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
    }
    ${MediaBreakpoints.minidesk2} {
      font-size: 15px;
      width: 135px;
    }
  }
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 35px;
  isolation: isolate;

  width: 604px;
  height: 456px;
  & > img {
    width: 233px;
    height: 233px;
    @media (${MediaBreakpoints.mobile}) {
      width: 153px;
      height: 153px;
    }
    ${MediaBreakpoints.minidesk2} {
      width: 200px;
      height: 200px;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    height: 100%;
    gap: 0px;
    justify-content: center;
  }
  ${MediaBreakpoints.ipad} {
    width: 518px;
    height: 100%;
  }
  ${MediaBreakpoints.minidesk} {
    width: 518px;
    height: 100%;
  }
`;
export const MintContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
`;
export const MintHead = styled.span`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  /* Font/Regular */

  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 26px;
  }
`;
export const MintSubHead = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-align: center;

  /* Grey/Light */

  color: var(--primary-subtext-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 18px;
  }
`;

export const EmptyWrapper = styled.div`
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);

  width: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: var(--offercard-background-color);
  /* Font/Disabled */

  border: 1px solid var(--textinput-border-color);
  border-radius: 24px;
  transition: all ease 0.3s;
  ${MediaBreakpoints.minidesk2} {
    padding: 25px;
  }
  :hover {
    box-shadow: 0px 0px 6px 1px #4d657e;
    cursor: pointer;
    transform: scale(1.01);
    border: 1px solid #4d657e;
  }
  @media (${MediaBreakpoints.mobile}) {
    width: auto;
    padding: 20px;
    gap: 9px;
  }
`;

export const ReferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
interface isPropsempty {
  isEmpty: boolean;
}
export const EmptyDomainTitle = styled.h4<isPropsempty>`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: var(--primary-text-color);
  margin: 0;
  ${MediaBreakpoints.minidesk2} {
    font-size: 23px;
  }
  @media (${MediaBreakpoints.mobile}) {
    font-size: ${(props) => (props.isEmpty ? "16px;" : "21px;")} margin
      ${(props) => props.isEmpty && "0px;"};
  }
`;

export const EmptyContent = styled.p<isPropsempty>`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--offercard-text-color);
  ${MediaBreakpoints.minidesk2} {
    font-size: 15px;
    margin-top: 8.5px;
  }
  @media (${MediaBreakpoints.mobile}) {
    font-size: ${(props) => (props.isEmpty ? "12px;" : "15px;")} margin
      ${(props) => props.isEmpty && "0px;"};
  }
`;

export const EmptyContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column-reverse;
  }
`;

export const ImageContentWrappr = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

export const EmptyAdjust = styled.div`
  width: 30%;
`;

export const ImageWrapper = styled.div`
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${MediaBreakpoints.mobile2}) {
    width: 80px;
    height: 80px;
  }
`;
export const EmptyImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${MediaBreakpoints.mobile}) {
    width: 45px;
    height: 45px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DualButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column-reverse;
    margin-top: 2rem;
    width: 100%;
  }
`;

export const RegButton = styled(OutlineButton)`
  width: 50%;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;
export const ManageButton = styled(FillButton)`
  width: 50%;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 80%;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column-reverse;
    margin-top: 2rem;
    width: 100%;
  }
  & > button {
    @media (${MediaBreakpoints.mobile}) {
      font-size: 14px;
    }
  }
`;
export const ScrollDiv = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    height: 100%;
    overflow-y: unset;
    padding: 0px !important;
  }
  ${MediaBreakpoints.ipad} {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  ${MediaBreakpoints.minidesk} {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  height: 410px;
  overflow-y: auto;
  width: 100%;
  overflow-x: unset;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 2rem;
  padding-right: 0.2rem;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transperent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(77, 101, 126, 0.54);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(77, 101, 126, 0.54);
  }
`;

export const Resend = styled.a`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-decoration-line: underline;

  /* Brand/Light */

  color: #81ecdf;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 12px;
  }
`;
export const WrappLink = styled.div`
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;
export const ButtonWrap = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
  & > button {
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
    }
    ${MediaBreakpoints.minidesk2} {
      padding-bottom: 9px !important;
      padding: 9px 13px;
      font-size: 12px;
    }
  }
`;

export const CancelBtn = styled(OutlineButton)`
  width: 50%;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;
export const SwicthBtn = styled(FillButton)`
  width: 50%;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;
export const MobileWrapper = styled.div`
  width: 255px;
`;
