import { useMobile } from "mediaQuerys/useMobile";
import Features from "../../assets/images/features.png";
import LFeatures from "../../assets/lightThemeImages/Lfeatures.png";
import { ReactComponent as DappFeature } from "../../assets/images/featuresvg.svg";
import { ReactComponent as DappFeatureList } from "../../assets/images/featureList.svg";
import List from "../../assets/images/list.png";
import {
  AlignCenter,
  FeatureList,
  FeatureWrapper,
  ImageWrapper,
  ListContent,
  ListWrapper,
  Title,
} from "./Features.style";

// const sentence = "Accessibleacross multiple chains ";
// const spacedSentence = sentence.padEnd(sentence.length + 30, " ");

const ListData = [
  "Accessible across multiple chains ",
  "A human-readable identity for your Web3 world",
  "Pseudonymous and unique NFT for all things blockchain",
  "Allows you to host censorship resistant websites",
];

const FeaturesModal = () => {
  const mobile = useMobile();
  const Theme = localStorage.getItem("theme");
  console.log(Theme);

  return (
    <FeatureWrapper>
      <AlignCenter>
        <ImageWrapper>
          <DappFeature />
          {/* <img
            src={Theme === "dark-theme" ? Features : LFeatures}
            alt="Features"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          /> */}
        </ImageWrapper>
      </AlignCenter>

      <AlignCenter>
        <Title>Why decentralised domains?</Title>
      </AlignCenter>

      <FeatureList>
        {ListData.map((list, ind) => {
          return (
            <ListWrapper key={ind}>
              <div>
                <DappFeatureList width={mobile ? "20" : "24"} />
              </div>
              {/* <img src={List} width={mobile ? "20" : "24"} /> */}
              <ListContent style={{ margin: 0 }}>{list}</ListContent>
            </ListWrapper>
          );
        })}
      </FeatureList>
    </FeatureWrapper>
  );
};

export default FeaturesModal;
