import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";

export const FaqTitle = styled.h4`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin: 20px 0;
  color: var(--primary-text-color);

  @media (${MediaBreakpoints.mobile}) {
    font-size: 24px !important;
    text-align: center;
  }
`;

export const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FaqDataWrapper = styled.div`
  width: 1130px;

  @media (${MediaBreakpoints.mobile}) {
    width: auto;
  }
  @media (${MediaBreakpoints.ipad}) {
    width: auto;
  }
  @media (${MediaBreakpoints.minidesk}) {
    width: auto;
  }
  @media (${MediaBreakpoints.minidesk2}) {
    width: auto;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: auto;
  }
`;

export const FaqSummary = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0 !important;
  }
`;

export const FaqAccordian = styled(Accordion)`
  padding: 20px;
  // background-color: #272d3c !important;
  background-color: var(--faq-background-color) !important;
  border: 1px solid var(--textinput-border-color);
  border-radius: 16px !important;
  margin-bottom: 10px;

  @media (${MediaBreakpoints.mobile}) {
    padding: 10px 0;
    margin-bottom: 5px;
  }
`;

export const FaqTitleTypography = styled(Typography)`
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 40px !important;
  color: var(--primary-text-color) !important;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px !important;
    line-height: 30px !important;
    // text-align: center;
  }
`;

export const FaqDescTypography = styled(Typography)`
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: var(--primary-subtext-color) !important;

  & .MuiAccordionSummary-content {
    padding: 0 !important;
  }

  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px !important;
  }
`;
