import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Container } from "@mui/material";
import Telegram from "assets/images/telegram2.png";
import Twitter from "assets/images/twitter2.png";
import CustomizedDialogs from "components/shared/Modal";
import { useState } from "react";
import { SuportItems } from "./SupportItem";
import {
  CopyRightBar,
  FooterSocialWrapper,
  FooterWrapper,
  ItemWrapper,
  LinkRedirect,
  MainWrapper,
  SocialIconWrapper,
  SocialWrapper,
  SupportModalWrapper,
} from "./copyRight.style";
const CopyRight = () => {
  const getCurrentYear = new Date().getFullYear();
  const [isOpen, setOpen] = useState(false);
  const openSupport = () => {
    setOpen(true);
  };
  const closeSupport = () => {
    setOpen(false);
  };
  return (
    <>
      <Container maxWidth="xl" style={{ padding: 0 }}>
        <MainWrapper>
          <FooterWrapper>
            <CopyRightBar>
              ©{getCurrentYear} dapps.co. All rights reserved.
            </CopyRightBar>
          </FooterWrapper>

          <SocialWrapper>
            {/* <CopyRightBar onClick={openSupport}>support</CopyRightBar> */}

            <LinkRedirect
              href="https://dapps.olvy.co/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <CopyRightBar>Change Log</CopyRightBar>
            </LinkRedirect>
            <SocialIconWrapper>
              <FooterSocialWrapper
                href="https://twitter.com/dapps_co?s=21"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </FooterSocialWrapper>
              <FooterSocialWrapper
                href="https://t.me/dapps_co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramIcon />
              </FooterSocialWrapper>
              <FooterSocialWrapper
                href="https://www.linkedin.com/company/dappsco/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </FooterSocialWrapper>
            </SocialIconWrapper>

            {/* <FooterSocialWrapper
            href="https://discord.com/invite/SH5pJuj28U"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FooterDiscrod}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </FooterSocialWrapper> */}
          </SocialWrapper>
        </MainWrapper>
      </Container>
      <CustomizedDialogs open={isOpen} onClose={closeSupport} title="Support">
        <SupportModalWrapper>
          <ItemWrapper>
            <SuportItems image={Twitter} title="Contact via Twitter" />
            <SuportItems image={Telegram} title="Contact via Telegram" />
          </ItemWrapper>
        </SupportModalWrapper>
      </CustomizedDialogs>
    </>
  );
};

export default CopyRight;
