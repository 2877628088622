import { JsonRpcProvider } from "@ethersproject/providers";
import { rpcUrls } from "../connectors/chain";

export const getJsonProvider = (networkId: number) => {
  // const provider = new JsonRpcProvider(rpcUrls[networkId]);
  // const token = await getJwt();
  // const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI2Mzk0NjQsImV4cCI6MTY3Mjg5ODY2NH0.FSMHMLEsWAygoPLfaTyyYppcquy-J3UyQzpcie7PqBplyAxj86BFHEfYKmOi5E-WWgBiM4bUBWkt2r_nN50NKlAQLWgS0_4rvIn5MGNmT7LFUaCWZT3kuEQf3ADh3YpAKPawTaztHd22fGVYlB5gRmLQoRFPAN2kjW24VSMfbPtTbgNqwV67Jtl4MFVPTmmdujlmcLfy4YAgtDcLmKm_YNTuyXY3YkFaVdJmbX1RD5TmIQWxGfc4DHZ72xFsPUAwwdz4IU1LMMiFVlDf-5NjsQy6Pess2WxIXz938TnvIBLsMX1B58aUo8y6g-kyUkpp7WohRqfciICZ-6bAshs2tw";
  const token = localStorage.getItem("DAPPS_JWT");
  // console.log("local storage jwt: ", token);
  const provider = new JsonRpcProvider({
    url: rpcUrls[networkId],
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return provider;
};
