import { FillButton, OutlineButton } from "components/shared/Buttons";
import styled from "styled-components";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";

export const ConnectWalletText = styled.h2`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 88px;
  text-align: center;
  color: var(--primary-text-color);
  margin: 0;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const ConnectWalletWrapper = styled.div`
  padding-left: 155px;
  padding-right: 155px;
  padding-top: 180px;
  padding-bottom: 0px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    height: 100vh;
    padding: 0;
    // height: auto;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  ${MediaBreakpoints.ipad} {
    width: 100%;
    padding: 0;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  ${MediaBreakpoints.minidesk} {
    width: 100%;
    padding: 0;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  ${MediaBreakpoints.minidesk2} {
    width: 100%;
    padding: 0;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const WalletWrapper = styled.div`
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
}
`;

export const WalletDivWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 668px;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const HomeButton = styled(OutlineButton)`
  width: 322px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;
export const ConnectButton = styled(FillButton)`
  width: 322px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;
export const WalletSubTitle = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  text-align: center;

  /* Font/Subdued */

  color: var(--primary-subtext-color);

  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;
