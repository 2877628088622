import React from "react";
import { CartDomainsList, DomianName, Duration } from "./Cart.style";
import { ReactComponent as Delete } from "../../assets/images/svgImages/delete.svg";

function CartDomainList({ domainName, domainDuration, domainPrice }) {
  // const Matic =
  return (
    <>
      <CartDomainsList>
        <DomianName style={{ width: "240px" }}>{domainName}</DomianName>
        <Duration>{domainDuration}</Duration>
        <DomianName> {domainPrice.toUpperCase()}</DomianName>
        <Delete style={{ cursor: "pointer" }} />
      </CartDomainsList>
    </>
  );
}

export default CartDomainList;
