import { MediaBreakpoints } from 'mediaQuerys/MediaBreakpoints'
import styled from 'styled-components'
export const MainContainer=styled.div`
padding-left:30px;
padding-right:30px;
// padding-top:40px;
@media(${MediaBreakpoints.mobile}){
   padding-left:10px;
padding-right:10px; 
padding-top:0px;
}
` 