import styled from "styled-components";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";

export const CustomDivider = styled(Divider)`
  border-color: var(--divider-background-color) !important;
`;

export const ShareLink = styled.a`
  text-decoration: none !important;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainMenuList = styled(MenuItem)`
  font-family: "Ubuntu" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  text-transform: lowercase !important;
  ${MediaBreakpoints.minidesk2} {
    font-size: 20px !important;
  }
`;
export const CustomMenuItem = styled(MenuItem)`
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 36px !important;
  color: #f7f7f7;
  ${MediaBreakpoints.minidesk2} {
    font-size: 15px !important;
  }
  & svg {
    ${MediaBreakpoints.minidesk2} {
      font-size: 25px !important;
    }
  }
`;

export const AccountLogo = styled(AccountCircleIcon)`
  color: var(--account-logo-color) !important;
`;

export const NFTLogo = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  ${MediaBreakpoints.minidesk2} {
    width: 28px;
    height: 28px;
  }
`;

export const AccountNft = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  @media (${MediaBreakpoints.mobile}) {
    width: 16px;
    height: 16px;
    margin: 0;
  }
`;

export const ConnetWrapper = styled(Button)`
  //   background-color: rgb(255, 255, 255) !important;
  color: var(--connectmenu-text-color) !important;
  text-transform: lowercase !important ;
  background: transparent !important;

  display: flex !important;
  flex-direction: row !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  cursor: pointer !important;

  width: 200px !important;
  height: 50px !important;
  padding: 0px !important;

  border: 2px solid var(--connectmenu-text-color) !important;
  border-radius: 100px !important;

  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;

  @media (${MediaBreakpoints.mobile}) {
    width: 48px !important;
    height: 32px !important;
  }
`;

export const CreditText = styled.p`
  margin: 0;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode/Grey/Light */

  color: var(--primary-text-color);
`;
