import { useWeb3React } from "@web3-react/core";
import CloseIcon from "assets/images/close.png";
import Logo from "assets/images/logo.png";
import darkThemeLogo from "assets/images/darkThemeLogo.png";
import lightTheme from "assets/images/Sun.png";
import { ReactComponent as Closesvg } from "assets/images/svgImages/closeSvg.svg";

import { FillButton } from "components/shared/Buttons";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DappsLogo } from "assets/images/svgImages/dLogo.svg";
import { ReactComponent as MobileDappsLogosvg } from "assets/images/svgImages/mobileLogo.svg";

import {
  DrawerContent,
  DrawerHeader,
  DrawerLink,
  DrawerList,
  ImageWrapper,
  MobileDrawerStyle,
  ToggleWrapper,
} from "./header.style";
import { Image } from "components/features/Features.style";
import SwicthToggle from "components/shared/Switch";
import SoulContext from "context/context";
interface isProps {
  isOpen: boolean;
  setOpen: (value: any) => void;
  hadleConnect: () => void;
  featureClick: () => void;
  faqClick?: () => void;
  handleAbout?: () => void;
}

const MobileDrawer = ({
  isOpen,
  setOpen,
  hadleConnect,
  featureClick,
  handleAbout,
  faqClick,
}: isProps) => {
  const Theme = useContext(SoulContext);

  const { theme, handleTheme } = Theme;

  const [searchValue, setSearchValue] = useState("");
  const [errorState, setErrorState] = useState({
    error: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const Close = () => {
    setOpen(false);
  };

  const handleSwitchTheme = () => {
    handleTheme();
    // Close();
  };

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    setErrorState({
      error: false,
      errorMessage: "",
    });
  };
  const SearchDomain = (event: any) => {
    const regex = new RegExp(/[ `!@#$%^&*()_+={};':"|,.<>?~]/);
    if (searchValue.length < 3) {
      setErrorState({
        error: true,
        errorMessage: "Please enter at least 3 characters",
      });
    } else if (searchValue.length > 250) {
      setErrorState({
        error: true,
        errorMessage: "Please enter less than 250 characters",
      });
    } else if (regex.test(searchValue)) {
      setErrorState({
        error: true,
        errorMessage: "Special characters except (“-”) are not allowed",
      });
    } else {
      if (event.key === "Enter") {
        setOpen(false);
        navigate(`/search?search=${searchValue}`);
      }
    }
  };

  const SearchDomainClick = (event: any) => {
    const regex = new RegExp(/[ `!@#$%^&*()_+={};':"|,.<>?~]/);
    if (searchValue.length < 3) {
      setErrorState({
        error: true,
        errorMessage: "Please enter at least 3 characters",
      });
    } else if (searchValue.length > 250) {
      setErrorState({
        error: true,
        errorMessage: "Please enter less than 250 characters",
      });
    } else if (regex.test(searchValue)) {
      setErrorState({
        error: true,
        errorMessage: "Special characters except (“-”) are not allowed",
      });
    } else {
      setOpen(false);
      navigate(`/search?search=${searchValue}`);
    }
  };

  const { active, account, activate, chainId, library } = useWeb3React();
  return (
    <MobileDrawerStyle anchor={"left"} open={isOpen} onClose={Close}>
      <DrawerHeader>
        <Closesvg onClick={Close} className="close-icon" />
        {/* <img
          src={CloseIcon}
          alt="close"
          width={24}
          height={24}
          className="close-icon"
          onClick={Close}
        /> */}
        <MobileDappsLogosvg />
        {/* <img src={Logo} width="87.52px" alt="logo" height="24px" /> */}
      </DrawerHeader>

      <DrawerContent className="slide-right">
        {/* <SearchBox style={{ marginTop: "2em" }} className="slide-right">
          <CustomeTextfield
            placeholder="Search Domain"
            onChange={onChangeSearch}
            onKeyDown={SearchDomain}
            autoComplete="off"
            error={errorState?.error}
            sx={{
              "& input": {
                padding: "12px",
                border: "unset",
                "::placeholder": {
                  fontSize: "16px",
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconContainer onClick={SearchDomainClick}>
                  <img src={MobileIcon} alt="" width={"100%"} />
                </IconContainer>
              ),
            }}
          />
        </SearchBox> */}
        <DrawerList>
          <DrawerLink onClick={handleAbout}>About 5ire</DrawerLink>
          <DrawerLink onClick={featureClick}>Features</DrawerLink>
          <DrawerLink onClick={faqClick}>FAQ</DrawerLink>
        </DrawerList>

        {active && account ? null : (
          <FillButton className="mobile-btn slide-right" onClick={hadleConnect}>
            Connect Wallet
          </FillButton>
        )}
      </DrawerContent>

      <ToggleWrapper>
        <ImageWrapper onClick={handleSwitchTheme}>
          <Image src={theme === "dark-theme" ? darkThemeLogo : lightTheme} />
        </ImageWrapper>
        {/* <SwicthToggle /> */}
      </ToggleWrapper>
    </MobileDrawerStyle>
  );
};
export default MobileDrawer;
