import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
interface isHeaderProps {
  isSticky?: boolean;
}
export const HeaderWrap = styled.div<isHeaderProps>`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  padding-top: 25px;
  // padding-left: 30px;
  // padding-right: 30px;
  @media (${MediaBreakpoints.mobile}) {
    justify-content: start;
    position: relative;
    padding-top: 10px;
    // padding-left: 10px;
    // padding-right: 10px;
  }
  ${MediaBreakpoints.minidesk2} {
    padding-top: 22px;
    padding-bottom: 18px;
  }
  background: ${(props) => (props.isSticky ? "#091430;" : null)}
    ${(props) =>
      props.isSticky &&
      `position: sticky !important;
    top: 0px;
   
    animation-duration: 0.8s;
    transition: all 0.5s;
    animation-name: stickySlideDown;
    padding-bottom: 25px;
    z-index:1;
    padding-top: 25px;
    @keyframes stickySlideDown {
      0% {
        opacity: 0.7;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @media (${MediaBreakpoints.mobile}) {
      justify-content: start;
      position: relative;
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
    }
    ${MediaBreakpoints.minidesk2}{
padding-top:18px;
padding-bottom:18px;
    }
    `};
`;
export const StikyHeaderWrap = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  position: sticky !important;
  top: 0px;
  background: #091430;
  height: 100px;
  padding-left: 2rem;
  padding-right: 2rem;
  animation-duration: 0.8s;
  transition: ease-out 0.5s;
  animation-name: stickySlideDown;
  @keyframes stickySlideDown {
    0% {
      opacity: 0.7;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (${MediaBreakpoints.mobile}) {
    justify-content: start;
    align-items: center;
    height: 70px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const LogoWrap = styled.div`
  width: 131.28px;
  height: auto;
  :hover {
    cursor: pointer;
  }
  ${MediaBreakpoints.minidesk2} {
    width: 125px;
    height: 30px;
  }
`;
export const HeaderLinkWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
  & button {
    width: 200px !important;
    height: 50px !important;
    padding: 0px !important;
  }
  ${MediaBreakpoints.ipad} {
    gap: 20px;
  }
`;
export const HeaderText = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  color: var(--primary-text-color);
`;

export const WalletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
`;
interface isProps {
  isConnected: boolean;
}
export const MobileLogoWrapper = styled.div<isProps>`
  display: flex;
  justify-content: space-between;

  width: ${(props) => (props.isConnected ? "100%;" : "60%;")};
  // & .hamburger {
  //   position: absolute;
  //   left: 0px;
  // }
  & button {
    width: 55px !important;
    height: 32px !important;
    padding: 0;
    padding-bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const MobileDrawerStyle = styled(Drawer)`
  & .MuiPaper-root {
    background: var(--textinput-background-color);
    width: 92%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    position: relative;
  }
`;
export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  & .close-icon {
    position: absolute;
    left: 0px;
    top: 0px;
  }
`;
export const DrawerContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & .mobile-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    width: 100%;
    height: 40px;

    align-items: center;
    display: flex;
    justify-content: center;
    /* Font/Black */

    color: var(--button-label-color);
  }
  & .mobile-btn2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    width: 100%;
    height: 40px;

    align-items: center;
    display: flex;
    justify-content: center;
    /* Font/Black */

    color: white;
  }
`;
export const DrawerList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 100%;
  height: 56px;
  margin-top: 20px;
`;
export const DrawerLink = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Font/Regular */

  color: var(--primary-text-color);
`;
export const SearchBox = styled.div`
  width: 100%;
`;
export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  :hover {
    cursor: pointer;
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 30px;
    height: 30px;
  }
`;
export const Ipadwrapper = styled.div`
  & .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: relative;
  }

  & .input {
    border-style: none;
    height: 29px;
    width: 0px;
    padding: 10px;
    outline: none;
    border-radius: 50%;
    transition: 0.5s ease-in-out;
    background-color: #091430;
    box-shadow: 0px 0px 3px #f3f3f3;
    padding-right: 40px;
    color: #f7f7f7;
  }

  & .input::placeholder,
  .input {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  & .input::placeholder {
    color: #4d657e;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    cursor: pointer;
    width: 50px !important;
    height: 50px !important;
    outline: none;
    border-style: none;
    border-radius: 50%;
    pointer-events: painted;
    background-color: transparent;
    transition: 0.2s linear;
  }

  & .icon:focus ~ .input,
  & .input:focus {
    box-shadow: none;
    width: 130px;
    border-radius: 0px;
    background-color: transparent;
    border: 1px solid #4d657e;
    transition: all 500ms;
    border-radius: 100px;
  }
`;

export const ToggleWrapper = styled.div`
position: absolute;
    right: 40px;
    bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
`;

export const ImageWrapper = styled.div`
  width: 30px;
  height: 30px;
`;
