import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";

export const CheckoutModalWrapper = styled.div`
  width: 668px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 8px;
    height: 100%;
    position: relative;
    justify-content: space-between;
  }
  ${MediaBreakpoints.ipad} {
    width: 518px;
  }
  ${MediaBreakpoints.minidesk} {
    width: 518px;
  }
`;
export const CheckoutModalWrapper2 = styled.div`
  width: 668px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 400px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 8px;
    height: auto;
    position: relative;
  }
  ${MediaBreakpoints.ipad} {
    width: 518px;
    height: auto;
  }
  ${MediaBreakpoints.minidesk} {
    width: 518px;
    height: auto;
  }
`;

export const BuyText = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  /* identical to box height, or 150% */

  text-align: right;
  text-decoration-line: underline;

  /* Brand/Light */

  color: #81ecdf;
`;

export const CheckoutDetails = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${MediaBreakpoints.mobile}) {
    margin-top: 0.8rem;
  }
`;

export const Detailkey = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
    letter-spacing: 1.1px;
  }
  ${MediaBreakpoints.ipad} {
    letter-spacing: 1.2px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 18px;
  }
`;

export const DetailValue = styled(Detailkey)`
  font-weight: 700;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
    letter-spacing: 1.5px;
  }
  ${MediaBreakpoints.minidesk2} {
    font-size: 18px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #4d657e;
`;

export const ReferralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  //   padding: 20px 0;
  @media (${MediaBreakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

export const ReferralTitle = styled(Detailkey)`
  margin: 20px 0;
  margin-top: 10px;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  ${MediaBreakpoints.ipad} {
    margin-top: 15px;
  }
  ${MediaBreakpoints.minidesk2} {
    margin-top: 0px;
  }
  ${MediaBreakpoints.minidesk} {
    margin-top: 15px;
  }

  & > button {
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* Background-Dark/Black */

      color: var(--button-label-color);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ${MediaBreakpoints.minidesk2} {
      font-size: 14px;
    }
  }
  @media (${MediaBreakpoints.mobile}) {
    // position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
  }
`;

export const CheckModalButtonWrapper = styled(ButtonWrapper)`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const RefferWrapper = styled.div`
  display: flex;
  // align-items: center;
  gap: 17px;
  & > button {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--connectmenu-text-color);
    width: 119px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.5px;
    padding: 0px;
    padding-bottom: 0px !important;
    @media (${MediaBreakpoints.mobile}) {
      font-size: 12px;
    }
    :disabled {
      border: 1px solid #4d657e;
      /* Shadow/xs */
      padding: 0px;
      padding-bottom: 0px !important;
      filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      @media (${MediaBreakpoints.mobile}) {
        font-size: 12px;
      }
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Dark mode/Font/Disabled */

      color: #4d657e;
    }
  }
`;

export const RefrrelWrapper = styled.div`
  display: flex;
`;

export const CreditWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReferralText = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-text-color);
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
`;
