import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import {
  ETH_CHAIN,
  BSC_CHAIN,
  POLYGON_CHAIN,
  FIRECHAIN_CHAIN,
  polygonMumbaiRpcUrl,
  POLYGON_MUMBAI_CHAIN,
} from "./chain";
import { ethRpcUrl, bscRpcUrl, polygonRpcUrl, firechainRpcUrl } from "./chain";

const supportedChainIds: number[] = [
  ETH_CHAIN,
  BSC_CHAIN,
  POLYGON_CHAIN,
  FIRECHAIN_CHAIN,
  POLYGON_MUMBAI_CHAIN
];

const RPC: { [chainId: number]: string } = {
  1: ethRpcUrl,
  56: bscRpcUrl,
  137: polygonRpcUrl,
  997: firechainRpcUrl,
  80001: polygonMumbaiRpcUrl
};

export const injected = new InjectedConnector({
  supportedChainIds,
});

export const walletconnect = new WalletConnectConnector({
  rpc: RPC,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  supportedChainIds,
  qrcodeModalOptions: {
    mobileLinks: [
      "rainbow",
      "metamask",
      "argent",
      "trust",
      "imtoken",
      "pillar",
      "trustwallet",
    ],
    desktopLinks: ["trustwallet"],
  },
});
