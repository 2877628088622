import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import Credit from "assets/images/credit.png";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useMobile } from "mediaQuerys/useMobile";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AccountLogo,
  AccountNft,
  ConnetWrapper,
  CreditText,
  CustomDivider,
  CustomMenuItem,
  MainMenuList,
  NFTLogo,
} from "./menu.style";
import { Logo, WalletLogo } from "components/wallet/walletModal.style";
import { useDisconnect } from "components/hook/useWeb3EagerConnect";
import { formatWithoutRounding, miniDomain } from "utilities";
import { CustomTooltip } from "components/searchList/searchlist.style";
import { TableDataTooltip } from "components/manageDns/managedns.style";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import { SVGCard } from "components/SVGCard";
import { BigNumber } from "@ethersproject/bignumber";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 16,
    border: "1px solid var(--accountmenu-border-color)",
    padding: "16px",
    marginTop: theme.spacing(1),
    minWidth: 322,
    backgroundColor: "var(--accountmenu-background-color)",
    color: "var(--primary-text-color)",
    [`${MediaBreakpoints.minidesk2}`]: {
      minWidth: 260,
      padding: "10px",
    },
    // color:
    //   theme.palette.mode === "light"
    //     ? "rgb(55, 65, 81)"
    //     : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 32,
        color: "#F7F7F7",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface WalletProps {
  walletAddress: string;
  nftUrl: string;
  credits?: BigNumber | string;
  // handleProfile: () => void;
}

export default function CustomizedMenus({
  walletAddress,
  nftUrl,
  credits,
}: WalletProps) {
  const mobile = useMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const disconnect = useDisconnect();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    setAnchorEl(null);
    if (
      location.pathname === "/partner" ||
      location.pathname === "/partner/search"
    ) {
      setTimeout(() => {
        navigate(`/partner/my-profile`);
      }, 500);
    } else {
      setTimeout(() => {
        navigate("/my-profile");
      }, 500);
    }
  };
  const handleDisconnect = () => {
    setAnchorEl(null);
    disconnect();
    localStorage.removeItem("wallet");
  };

  return (
    <div>
      <TableDataTooltip
        content={walletAddress}
        arrow={false}
        disabled={walletAddress.length > 15 ? false : true}
      >
        <ConnetWrapper
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {mobile ? (
            <AccountNft>
              <Logo src={nftUrl} alt="logo" style={{ borderRadius: 100 }} />
            </AccountNft>
          ) : (
            <>
              {/* <SVGCard svgUrl={nftUrl} size="sm"/> */}
              <AccountNft>
                <Logo src={nftUrl} alt="logo" style={{ borderRadius: 100 }} />
              </AccountNft>
              {miniDomain(walletAddress)}
            </>
          )}
        </ConnetWrapper>
      </TableDataTooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MainMenuList onClick={handleClose}>
          {/* <SVGCard svgUrl={nftUrl} size="md"/> */}
          <NFTLogo>
            <Logo src={nftUrl} alt="logo" style={{ borderRadius: 100 }} />
          </NFTLogo>
          {miniDomain(walletAddress)}
        </MainMenuList>

        <CustomMenuItem>
          <img
            src={Credit}
            style={{
              marginRight: "12px",
              marginLeft: "6px",
            }}
          />
          <CreditText>
            Store Credits :
            <span
              style={{ color: "var(--primary-text-color)", fontWeight: "700" }}
            >
              {" "}
              {formatWithoutRounding(credits, 2)}
            </span>{" "}
          </CreditText>
        </CustomMenuItem>

        <CustomDivider sx={{ my: 0.5 }} />
        <CustomMenuItem onClick={handleProfile}>
          {/* <AccountCircleIcon /> */}
          <AccountLogo />
          My Domains
        </CustomMenuItem>
        <CustomMenuItem
          onClick={handleDisconnect}
          style={{ color: "var(--disconnect-text-color)" }}
        >
          <AccountBalanceWalletIcon
            style={{ color: "var(--disconnect-text-color)" }}
          />
          Disconnect
        </CustomMenuItem>
      </StyledMenu>
    </div>
  );
}
