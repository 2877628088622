export const dappSupportedNetworks = [137];

export const dappsDnsAddress = (networkID: number) => {
  switch (networkID) {

    // firechain
    case 997:
      return "0x271437C9B2069F13Cc197B9e12A02ED276ae3A85";
    
    default:
      return "";
  }
};

export const dappsDnsNewAddress = (networkID: number) => {
  switch (networkID) {
    // polygon
    case 137:
      return "0xA057D3a610aD5d42b1CDD2e879088edf1360b2F0";

    // polygon mumbai
    case 80001:
      // return "0xEfd9CF617C46c4f538e49da5ddF29ACaEE50A2a8";
      // return "0xbBb5b2786646270f546621E0EdF03353E0609524";
      // return "0x7D82b1eE4f8A887db2d5bC33273e2B6C7d1fAB3a";
      return "0xfa6F1efE09880AA281F62Eae59c4E75f2ff6ecF2";
    
    default:
      return "";
  }
};

export const dappsDnsNew2Address = (networkID: number) => {
  switch (networkID) {
    // polygon
    case 137:
      return "0x270E731A7Ea4898B43cd53d0294471d2F068370D";

    // polygon mumbai
    case 80001:
      // return "0xEfd9CF617C46c4f538e49da5ddF29ACaEE50A2a8";
      // return "0xbBb5b2786646270f546621E0EdF03353E0609524";
      // return "0x7D82b1eE4f8A887db2d5bC33273e2B6C7d1fAB3a";
      return "0x2cF7485086626bADB9f350CfAf3c59DD8f037387";
    
    default:
      return "";
  }
};