import styled from "styled-components";
import { Container } from "@mui/material";
import { CustomeTextfield } from "components/shared/Textfield";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { MuiChipsInput } from "mui-chips-input";
import { TableData } from "components/shared/Table/table.style";
import { FillButton, OutlineButton } from "components/shared/Buttons";
import MenuItemUnstyled, {
  menuItemUnstyledClasses,
} from "@mui/base/MenuItemUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { CustomizeBox } from "components/myProfile/myprofile.style";
import { TextField } from "@mui/material";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
// import { CartHeaderWrapper } from "components/bulkDomainCart/Cart.style";

export const BulkMenuItem = styled(MenuItem)`
  color: var(--primary-text-color) !important;
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
`;

export const BulkSelect = styled(Select)`
  border: 1px solid #4d657e !important;
  border-radius: 8px !important;

  padding: 2px !important;

  & .MuiSelect-select {
    padding: 8px !important;
    color: var(--primary-text-color);
  }
  & .MuiSelect-icon {
    color: var(--primary-text-color);
  }

  & .MuiList-root {
    background: #324b66 !important;
    /* Dark mode/Grey/Light */

    border: 1px solid #91b6d1 !important;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
    border-radius: 8px !important;
  }
`;

export const BulkContainer = styled(Container)`
  max-width: 1440px !important;
  padding: 0 155px;

  @media (${MediaBreakpoints.mobile}) {
    padding: 0 0px !important;
  }
`;

export const AlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BulkHeading = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: var(--primary-text-color);
  margin: 10px 0;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const BulkSubHeading = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--connectbutton-color);
  margin: 10px 0;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const UploadContent = styled(BulkSubHeading)`
  color: var(--primary-subtext-color);
`;

export const SearchHeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: start;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const BulkSearchField = styled(CustomeTextfield)`
  width: 100%;
`;

export const AvailableChip = styled.div`
  //   width: 124px;
  height: 40px;
  padding: 0 20px;
  background: #087158;
  border: 1px solid #7ef992;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #7ef992;
`;

export const TakenChip = styled(AvailableChip)`
  //   width: 156px;

  background: var(--takenchip-bg-color);
  border: 1px solid var(--takenchip-border-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fbbbab;
`;

export const OwnChip = styled(AvailableChip)`
  background: var(--ownchip-bg-color);
  border: 1px solid var(--primary-subtext-color);
  color: var(--primary-subtext-color);
`;

export const ParentWrapper = styled.div`
  width: 620px;
  height: 350px;
  background: var(--skeleton-background-color);
  border: 1px dashed var(--textinput-border-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    border: none;
    background: none;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (${MediaBreakpoints.mobile}) {
    height: auto;
  }
`;

export const SearchButton = styled(FillButton)`
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export const UploadWrapper = styled.div`
  // width: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
`;

export const ChooseFileText = styled(BulkSubHeading)`
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--refer-bg-color);
`;

export const MuiChipsInputStyled = styled(MuiChipsInput)`
  color: var(--textinput-color);
  width: 100%;

  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (width: 1400px) {
    height: 430px !important;
  }
  @media (width: 1680px) {
    height: 430px !important;
  }
  @media (min-width: 1920px) and (max-width: 2560px) {
    height: 430px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transperent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(77, 101, 126, 0.54);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(77, 101, 126, 0.54);
  }

  & input {
    color: var(--primary-text-color);
    padding-right: 0 !important;
    padding-left: 0 !important;
    @media (${MediaBreakpoints.mobile}) {
      padding: 0 !important;
    }
    // border: 1px solid var(--textinput-border-color);
    // border-radius: 100px;
    // border: none;
    // background: var(--textinput-background-color);
    ::placeholder {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4d657e;
    }
  }
`;

export const BulkTextFieldWrapper = styled.div`
  background: var(--textinput-background-color);
  border: 1px solid var(--textinput-border-color);
  border-radius: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;

  @media (${MediaBreakpoints.mobile}) {
    padding: 0 0px;
  }
`;

export const ClearWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: end;
  gap: 20px;

  @media (${MediaBreakpoints.mobile}) {
    padding: 10px;
    gap: 10px;
  }
`;

export const ClearAllText = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  color: var(--button-background-color);
  cursor: pointer;
`;

export const BulkTableData = styled(TableData)`
  color: var(--primary-text-color);
  font-weight: 700;
`;

export const SearchBtnWrapper = styled(OutlineButton)`
  width: 20%;
  padding-bottom: 12px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }

  ${MediaBreakpoints.ipad} {
    width: 40%;
  }
`;

export const CartHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
`;
export const AllCartButton = styled(OutlineButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CartButton = styled.div`
  background: var(--button-background-color);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 56px;

  position: fixed;
  bottom: 80px;
  right: 75px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  gap: 10px;
  color: var(--button-label-color);
  cursor: pointer;
  transition: ease-out 0.5s;
  :active {
    transform: scale(0.9);
  }

  @media (${MediaBreakpoints.mobile}) {
    right: 16px;
  }
`;

export const StyledMenuItem = styled(MenuItemUnstyled)(
  ({ theme }) => `
    width:424px;
    
  list-style: none;
  // padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;
font-family: 'Manrope';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
/* identical to box height, or 120% */


/* Grey/Lighter */
display:flex;
flex-direction:column;
align-items:center;
gap:10;
color: var(--primary-subtext-color);




:hover{
  background-color: var(--textinput-background-color);
}
  &:last-of-type {
    border-bottom: none;
  }



  &:hover:not(.${menuItemUnstyledClasses.disabled}) {
    cursor:pointer;
  }
  `
);

export const Popper = styled(PopperUnstyled)`
  z-index: 1;
`;

export const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: var(--textinput-background-color);
  /* Grey/Light */
  
  border: 1px solid var(--accountmenu-border-color);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  border-radius: 8px;

  transform: translate3d(150px, 0px, 0px) !important;
  `
);

export const FilterHeader = styled.div`
  background: var(--filter-bg-color);
  border-bottom: 1px solid var(--textinput-border-color);
  width: 100%;
`;

export const FilterFooter = styled(FilterHeader)`
  border-top: 1px solid #91b6d1;
  border-bottom: none;
`;

export const FilterCenterDiv = styled.div`
  width: 100%;
  display: flex;
  height: 360px;
`;
export const TabWrapper = styled.div`
  width: 144px;
  background: var(--skeleton-background-color);
  @media (${MediaBreakpoints.mobile}) {
    width: 104px;
  }

  // padding: 10px;
`;

export const ListWrapper = styled.div`
  width: 280px;
  height: inherit;
  padding: 10px;
  background: var(--filter-bg-color);
  @media (${MediaBreakpoints.mobile}) {
    height: auto;
  }
`;

export const FilterCustomizeBox = styled(CustomizeBox)`
  & .MuiButtonBase-root.Mui-selected {
    width: 144px;
    height: 56px;
    border-radius: 0px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    align-items: center;
    color: var(--primary-text-color);
    background: var(--filter-bg-color);
    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
      font-size: 16px;
    }
  }
  & .MuiButtonBase-root {
    color: var(--primary-text-color);
    width: 144px;
    height: 56px;
    font-size: 20px;
    align-items: center;

    @media (${MediaBreakpoints.mobile}) {
      width: 100%;
      font-size: 16px;
    }
  }

  & .MuiTabs-flexContainer {
    margin-top: 10px;
    @media (${MediaBreakpoints.mobile}) {
      flex-direction: column;
    }
  }
`;

export const FilterHeaderTitle = styled.div`
  padding: 16px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--primary-text-color);
`;

export const FilterFooterWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

export const ClearBtn = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: var(--disconnect-text-color);
`;

export const ApplyBtn = styled(ClearBtn)`
  color: var(--primary-border-color);
`;

export const SearchTextField = styled(TextField)`
width:100%;

& .MuiOutlinedInput-notchedOutline {
  border-color: #BEDDEF; !important;
  border-radius: 100px !important;
}

& input {
  color: #91B6D1;
  ::placeholder {
    font-family: 'Manrope';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


/* Dark mode/Grey/Light */

color: #91B6D1;
  }
  & .MuiOutlinedInput-root {
    border: 1px solid var(--skeleton-background-color);
    

    // height: 72px;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

}
`;
