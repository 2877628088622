import { gql } from "@apollo/client";

export const REGISTERED_DOMAINS = gql`
  query dapps($account: String!) {
    registeredDomains(where: { account: $account }) {
      id
      domain
      account
      dappId
      name
      tld
    }
  }
`;

export const ALL_DOMAINS = gql`
  query domains {
    domains {
      id
      count
      domain
      metadataurl
      referrerdomain
      name
      islifetime
      tokenid
      useraddress
      tld
    }
  }
`;

export const DOMAINS_BY_ACCOUNT = gql`
  query dapps($useraddress: String!) {
    domains(where: { useraddress: $useraddress }) {
      id
      domain
      useraddress
      islifetime
      tokenid
      metadataurl
      name
      tld
      expirytimestamp
    }
  }
`;

export const ALL_RECORDS = gql`
  query dapps {
    records {
      id
      type
      name
      content
      domain
      cid
      useraddress
      priority
      chainid
      timestamp
    }
  }
`;

export const RECORDS_BY_NAME = gql`
  query dapps($name: String!) {
    records(where: { name: $name }) {
      id
      type
      name
      content
      domain
      cid
      useraddress
      priority
      chainid
      timestamp
    }
  }
`;

export const DOMAIN_NETWORKS = gql`
  query domains($domain: String!) {
    domainNetworks(where: { domain: $domain }) {
      walletaddress
      networktype
      id
      domain
    }
  }
`;

export const DOMAIN_SOCIALS = gql`
  query domains($domain: String!) {
    domainSocials(where: { domain: $domain }) {
      username
      socialtype
      id
      domain
    }
  }
`;