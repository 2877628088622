import React, { lazy, Suspense, useEffect, useState } from "react";
// import HomePage from "pages";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainContainer } from "app.style";
import { Web3ReactProvider } from "@web3-react/core";
import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";

// import { FAQ } from "components/FAQ";
import Web3ReactManager from "components/Web3ReactManager";
import PageLoader from "components/loaders/pageLoader";
import ReactGA from "react-ga4";

import { useMobile } from "mediaQuerys/useMobile";
import CopyRight from "components/footer/copyRight";
import SoulContextState from "context/state";
import MainWrapper from "components/MainWrapp";
import { FAQ } from "components/FAQ";
import jwtDecode from "jwt-decode";
import setuHttp from "utilities/http";
import { Provider } from "react-redux";
import { store } from "./components/store";
import CreditSystem from "pages/admin/creditSystem";
// import BulkSearch from "components/bulkRegistartion/BulkSearch";
import DomainCart from "components/bulkDomainCart/DomainCart";

const BulkSearch = lazy(() => import("pages/bulkRegister"));
const HomePage = lazy(() => import("pages"));
const SearchList = lazy(() => import("pages/searchList"));
const Header = lazy(() => import("components/header"));
const MyProfilePage = lazy(() => import("pages/myProfile"));
const PersonalizePage = lazy(() => import("pages/personalize"));
const ManageDnsPage = lazy(() => import("pages/manageDns"));
const AboutPage = lazy(() => import("pages/about"));
const AdminPage=lazy(()=>import('pages/admin'));
const PartnerPage=lazy(() => import('pages/admin/partnerPage'));
const PatenerHomePage = lazy(
  () => import("components/domainSearch/PatnerDomain")
);
const PartnerSearchPage = lazy(
  () => import("components/searchList/partnerSearch")
);
const PartnerForm = lazy(() => import("components/admin/partnerPage/Form"))
const EditPartnerForm =lazy(()=>import('components/admin/partnerPage/EditForm'))

// GA Measurement ID
ReactGA.initialize("G-DKND7ERWGV");

function App() {
  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => {
    return new Web3Provider(provider);
  };
  const [offset, setOffset] = useState(0);
  const Mobile = useMobile();
  useEffect(() => {
    async function getRpcJwt() {
      try {
        const cachedJwt = localStorage.getItem("DAPPS_JWT");
        if (cachedJwt) {
          const decodedJwt: any = jwtDecode(cachedJwt);
          // console.log("cached jwt decoded: ", decodedJwt);
          if (decodedJwt.exp > new Date().getTime() / 1000) return;
        }

        const res = await setuHttp.get("/api/getJwt");
        // console.log("new jwt: ", res.data.token);
        localStorage.setItem("DAPPS_JWT", res.data.token);
      } catch (error) {
        console.log("Error in getRpcJwt(): ", error);
      }
    }
    getRpcJwt();

    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      {/* <PageLoader /> */}
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <SoulContextState>
            <Router>
              <Suspense fallback={<PageLoader />}>
                {/* <Header /> */}
                <MainContainer>
                  <MainWrapper>
                    <Routes>
                      <Route path="/" element={<HomePage />} />

                      <Route path="/search" element={<SearchList />} />
                      <Route path="/faq" element={<FAQ />} />
                      <Route path="/my-profile" element={<MyProfilePage />} />
                      <Route
                        path="/my-profile/personalize"
                        element={<PersonalizePage />}
                      />
                      <Route
                        path="/partner/my-profile/personalize"
                        element={<PersonalizePage />}
                      />
                      <Route path="/manage-dns" element={<ManageDnsPage />} />
                      <Route path="/partner/manage-dns" element={<ManageDnsPage />} />
                      {/* <Route path="/admin" element={<AdminDashboard />} /> */}
                      <Route path="/bulk" element={<BulkSearch />} />
                      <Route path="/bulkCart" element={<DomainCart />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/partner" element={<PatenerHomePage />} />
                      <Route path="/partner/search" element={<PartnerSearchPage />} />
                      <Route path="/admin/credit-system" element={<CreditSystem />} />
                      <Route path="/admin" element={<AdminPage />} />
                      
                      <Route path="/bulk" element={<BulkSearch />} />
                      <Route path="/bulkCart" element={<DomainCart />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/patner" element={<PatenerHomePage />} />
                      <Route path="/admin/partner-page" element={<PartnerPage />} />
                      <Route path="/admin/partner-form" element={<PartnerForm />} />
                      <Route path="/admin/partner-edit-form" element={<EditPartnerForm/>}/>
                      <Route
                        path="/partner/my-profile"
                        element={<MyProfilePage />}
                      />
                    </Routes>
                  </MainWrapper>
                </MainContainer>
              </Suspense>
            </Router>

            <Web3ReactManager />
          </SoulContextState>
        </Web3ReactProvider>
      </Provider>
      {/* <CopyRight/> */}
    </>
  );
}

export default App;
