import React, { createContext, useContext, useEffect, useState } from "react";
import SoulContext from "./context";

interface Props {
  children: any;
}

const SoulContextState = ({ children }: Props) => {
  const [domainData, setDomainData] = useState({
    name: "",
    nftUrl: "",
  });
  const [merkleProof, setMerkleProof] = useState({
    hasLatestData: false,
    data: null,
  });

  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme || "dark-theme";
  });

  const handleTheme = () => {
    if (theme === "dark-theme") {
      setTheme("light-theme");
    } else {
      setTheme("dark-theme");
    }
  };

  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <>
      <SoulContext.Provider
        value={{
          domainData,
          setDomainData,
          merkleProof,
          setMerkleProof,
          setTheme,
          theme,
          handleTheme,
        }}
      >
        {children}
      </SoulContext.Provider>
    </>
  );
};

export default SoulContextState;
