import React from "react";
import { BulkContainer } from "../bulkRegistartion/BulkRegistartion.style";
import CartHeader from "./CartHeader";
import {
  CartDomainScroll,
  DomainCartWrapper,
  DomainListWrapper,
  ListWrapper,
} from "./Cart.style";
import { CustomeTextfield } from "components/shared/Textfield";
import { FillButton } from "components/shared/Buttons";
import CartDomainList from "./CartDomainList";
import CartSummary from "./CartSummaryCard";
import { TableScrollDiv } from "components/manageDns/managedns.style";
import { useWeb3React } from "@web3-react/core";
import DomainCard from "./DomainCard";

import { useMobile } from "mediaQuerys/useMobile";

type cartDomainListProps = {
  domainName: string;
  domainDuration: string;
  domainPrice: string;
}[];

const cartDomainList: cartDomainListProps = [
  {
    domainName: "hello.5ire",
    domainDuration: "1 Year",
    domainPrice: "10 Matic",
  },
  {
    domainName: "hello.5ire",
    domainDuration: "1 Year",
    domainPrice: "10 Matic",
  },
  {
    domainName: "hello.5ire",
    domainDuration: "1 Year",
    domainPrice: "10 Matic",
  },
  {
    domainName: "hello.5ire",
    domainDuration: "1 Year",
    domainPrice: "10 Matic",
  },
  {
    domainName: "hello.5ire",
    domainDuration: "1 Year",
    domainPrice: "10 Matic",
  },
];

const DomainCart = () => {
  const { account, active, chainId, library, activate } = useWeb3React();
  const cartSummary: { walletAdd: string; orderTotal: string } = {
    walletAdd: account,
    orderTotal: "10 Matic",
  };

  const mobile = useMobile();

  return (
    <>
      <BulkContainer>
        <CartHeader listLength={cartDomainList.length} />
        <DomainCartWrapper>
          {mobile ? (
            <>
              {cartDomainList?.map((list) => {
                return <DomainCard {...list} />;
              })}
            </>
          ) : (
            <>
              <CartDomainScroll>
                <ListWrapper>
                  {cartDomainList?.map((list) => {
                    return <CartDomainList {...list} />;
                  })}
                </ListWrapper>
              </CartDomainScroll>
            </>
          )}

          <DomainListWrapper>
            <CartSummary {...cartSummary} />
          </DomainListWrapper>
        </DomainCartWrapper>
      </BulkContainer>
    </>
  );
};

export default DomainCart;
