import { ManageContainer } from "components/manageDns/managedns.style";
import { CustomeTextfield } from "components/shared/Textfield";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";

import styled from "styled-components";

export const NetworkLogoWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 30%;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  @media (${MediaBreakpoints.mobile}) {
    width: 30px;
    height: 30px;
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
  width: 70%;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    align-items: center;
  }
`;

export const PersonalizeTextField = styled(CustomeTextfield)`
  width: 100%;

  & input {
    padding: 10px;
    // width: 620px;
  }

  @media (${MediaBreakpoints.mobile}) {
    & input {
      padding: 6px;
    }
  }
`;

export const NetworkItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (${MediaBreakpoints.mobile}) {
    gap: 16px;
  }
`;

export const PersonilizeContainer = styled(ManageContainer)`
  max-width: 1440px !important;
`;
