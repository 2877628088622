import React from "react";
import "./pageloader.css";
const PageLoader = () => {
  return (
    <div className="wrapper">
      <div className="cube-loader">
        <div className="cube-top"></div>
        <div className="cube-wrapper">
          <span className="cube-span1"></span>
          <span className="cube-span2"></span>
          <span className="cube-span3"></span>
          <span className="cube-span4"></span>
        </div>
      </div>
      <span
        className="loading-text"
        style={{ color: "var(--primary-text-color)" }}
      >
        Loading...
      </span>
    </div>
  );
};
export default PageLoader;
