import Firechain from "../assets/images/5ire.png";

// ETH RPC url
export const ethRpcUrl = process.env.REACT_APP_ETHEREUM_RPC_URL;
// BSC RPC url
export const bscRpcUrl = process.env.REACT_APP_BSC_RPC_URL;

// POLYGON RPC url
export const polygonRpcUrl = process.env.REACT_APP_POLYGON_RPC_URL;

// POLYGON MUMBAI RPC url
export const polygonMumbaiRpcUrl = process.env.REACT_APP_POLYGON_MUMBAI_RPC_URL;

export const firechainRpcUrl = String(process.env.REACT_APP_FIRECHAIN_RPC_URL);

// ETH ChainId
export const ETH_CHAIN = 1;
// BSC ChainId
export const BSC_CHAIN = 56;
// Polygon ChainId
export const POLYGON_CHAIN = 137;
// Polygon Mumbai ChainId
export const POLYGON_MUMBAI_CHAIN = 80001;
// Firechain chainid
export const FIRECHAIN_CHAIN = 997;

interface RPC {
    [chainId: number]: string;
}

export const rpcUrls: RPC = {
    1: ethRpcUrl,
    56: bscRpcUrl,
    137: polygonRpcUrl,
    80001: polygonMumbaiRpcUrl,
    // 250: fantomRpcUrl,
    // 43114: avaxRpcUrl,
    // 42161: arbitrumRpcUrl,
    // 10: optimismRpcUrl,
    // 8081: shardeumRpcUrl,
    997: firechainRpcUrl,
};

export const chainConfig = {
    // 56: [
    //   {
    //     chainId: "0x38",
    //     chainName: "Binance Smart Chain (Mainnet)",
    //     nativeCurrency: {
    //       name: "BNB",
    //       symbol: "BNB",
    //       decimals: 18,
    //     },
    //     rpcUrls: [process.env.REACT_APP_BSC_RPC_URL_SWITCH],
    //     blockExplorerUrls: ["https://bscscan.com/"],
    //     iconUrls: ["https://unifarm.co/app/assets/icons/bnb.png"],
    //   },
    // ],
    137: [
      {
        chainId: "0x89",
        chainName: "POLYGON Mainnet",
        nativeCurrency: {
          name: "MATIC TOKEN",
          symbol: "MATIC",
          decimals: 18,
        },
        rpcUrls: [process.env.REACT_APP_POLYGON_RPC_URL_SWITCH],
        blockExplorerUrls: ["https://polygonscan.com/"],
        iconUrls: [
          "https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/matic.png",
        ],
      },
    ],
    80001: [
      {
        chainId: "0x13881",
        chainName: "POLYGON Mumbai",
        nativeCurrency: {
          name: "MATIC TOKEN",
          symbol: "MATIC",
          decimals: 18,
        },
        rpcUrls: [process.env.REACT_APP_POLYGON_MUMBAI_RPC_URL_SWITCH],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        iconUrls: [
          "https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/matic.png",
        ],
      },
    ],
    // 250: [
    //   {
    //     chainId: "0xFA",
    //     chainName: "Fantom Mainnet",
    //     nativeCurrency: {
    //       name: "Fantom",
    //       symbol: "FTM",
    //       decimals: 18,
    //     },
    //     rpcUrls: [process.env.REACT_APP_FANTOM_RPC_URL_SWITCH],
    //     blockExplorerUrls: ["https://ftmscan.com/"],
    //     iconUrls: [FTMLogo],
    //   },
    // ],
    // 42161: [
    //   {
    //     chainId: "0xA4B1",
    //     chainName: "Arbitrum Mainnet",
    //     nativeCurrency: {
    //       name: "Arbitrum",
    //       symbol: "ARB",
    //       decimals: 18,
    //     },
    //     rpcUrls: [arbitrumRpcUrl],
    //     blockExplorerUrls: ["https://arbiscan.io/"],
    //     iconUrls: [
    //       "https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/arb.png",
    //     ],
    //   },
    // ],
    // 10: [
    //   {
    //     chainId: "0xA",
    //     chainName: "Optimism Mainnet",
    //     nativeCurrency: {
    //       name: "Optimism",
    //       symbol: "ETH",
    //       decimals: 18,
    //     },
    //     rpcUrls: [optimismRpcUrl],
    //     blockExplorerUrls: ["https://optimistic.etherscan.io/"],
    //     iconUrls: [
    //       "https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/opt.png",
    //     ],
    //   },
    // ],
    // 43114: [
    //   {
    //     chainId: "0xA86A",
    //     chainName: "Avalanche Mainnet C-Chain",
    //     nativeCurrency: {
    //       name: "Avalanche",
    //       symbol: "AVAX",
    //       decimals: 18,
    //     },
    //     rpcUrls: [avaxRpcUrl],
    //     blockExplorerUrls: ["https://snowtrace.io/"],
    //     iconUrls: [
    //       "https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/avax.png",
    //     ],
    //   },
    // ],
    // 8081: [
    //   {
    //     chainId: "0x1F91",
    //     chainName: "Shardeum",
    //     nativeCurrency: {
    //       name: "Shardeum",
    //       symbol: "SHM",
    //       decimals: 18,
    //     },
    //     rpcUrls: [shardeumRpcUrl],
    //     blockExplorerUrls: ["https://explorer-liberty20.shardeum.org/"],
    //     iconUrls: [Shardeum],
    //   },
    // ],
    997: [
      {
        chainId: "0x3E5",
        chainName: "5ire(Testnet)",
        nativeCurrency: {
          name: "5irechain",
          symbol: "5IRE",
          decimals: 18,
        },
        rpcUrls: [firechainRpcUrl],
        blockExplorerUrls: ["https://rpc-testnet.5ire.network/"],
        iconUrls: [Firechain],
      },
    ],
  };
