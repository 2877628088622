import { TextField } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { MediaBreakpoints } from "mediaQuerys/MediaBreakpoints";
import styled from "styled-components";
import "../../../index.css";

export const CustomeTextArea = styled(TextareaAutosize)`
  width: 580px !important;
  height: 60px !important;
  border: 1px solid #90a7c0 !important;
  border-radius: 16px !important;
  outline: none;
  padding: 10px !important;

  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;

  background: var(--textinput-background-color) !important;
  color: var(--primary-text-color) !important;

  @media (${MediaBreakpoints.mobile}) {
    width: 94% !important;
  }
`;

export const CustomeTextfield = styled(TextField)`
  width: 433px;
  // color: #4d657e;
  color: var(--textinput-color);
  border: 1px solid var(--textinput-border-color);
  border-radius: 100px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
  & .MuiOutlinedInput-root {
    border-radius: 100px;
  }
  & .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: var(--error-text-color) !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #90a7c0 !important;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: #f38c7f !important;
  }
  &
    .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: #f38c7f !important;
  }
  & .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black !important;
  }
  & input {
    border: 1px solid var(--textinput-border-color);
    border-radius: 100px;
    background: var(--textinput-background-color);

    color: var(--textinput-color);
    ::placeholder {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #4d657e;
      ${MediaBreakpoints.minidesk2} {
        font-size: 15px;
        padding-left: 5px;
      }
    }
  }
`;

export const SmallTextField = styled(TextField)`
  width: 553px;
  color: #4d657e;
  border: 1px solid #4d657e;
  border-radius: 100px;
  background: #091430;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }
  & .MuiOutlinedInput-root {
    border-radius: 100px;
  }
  & .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f38c7f !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #4d657e !important;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: #f38c7f !important;
  }
  &
    .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: #f38c7f !important;
  }
  & .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black !important;
  }
  & input {
    border: 1px solid #4d657e;
    border-radius: 100px;
    background: #091430;
    color: #f7f7f7;
    border: unset;
    padding: 12.5px;
    padding-left: 1rem;
    ::-webkit-autofill {
      background: #091430 !important;
    }
    ::placeholder {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Font/Disabled */

      color: #4d657e;
      letter-spacing: 1.3px;
      ${MediaBreakpoints.minidesk2} {
        font-size: 15px;
        padding-left: 5px;
      }
    }
  }
`;
