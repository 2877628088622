import React, { useState } from "react";
import {
  CardWrapper,
  CenterWrapper,
  CreditCard,
  CreditFillButton,
  CreditInputWrapper,
  CreditTextField,
  Heading,
  Multiple,
  TextFieldLabel,
} from "./admin.style";
import { CustomeTextfield } from "components/shared/Textfield";
import { TextFieldWrapper } from "components/domainSearch/domain.style";
import {
  ButtonWrapper,
  BuyText,
} from "components/searchList/CheckoutModal.style";
import { FillButton } from "components/shared/Buttons";
import { VisitDapps } from "components/myProfile/myprofile.style";
import QuestionMark from "../../../assets/images/questionMark.png";

import "../../../App.css";
import CustomizedDialogs from "components/shared/Modal";
import DeniedModal from "./deniedModal";
import { PersonilizeContainer } from "components/myProfile/personalize.style";
import { BigNumber } from "@ethersproject/bignumber";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "utilities/getContract";
import {
  dappSupportedNetworks,
  dappsDnsNewAddress,
} from "constants/dappsContract";
import dappsDnsNewABI from "abi/DappsDnsNew";
import { ZeroAddress, isAddress } from "ethers";
import { LoaderClip } from "components/loaders/Cliploader";
import Success from "components/shared/Snackbar/Success";
import Error from "components/shared/Snackbar/Error";

const CreditSystem = ({ handleMultiple }) => {
  const [recipientAccount, setRecipientAccount] = useState<
    string | undefined
  >();
  const [amount, setAmount] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { account, chainId, library } = useWeb3React();
  const supportedChain = dappSupportedNetworks[0];
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [snackbarHeader, setSnackbarHeader] = useState("");
  const [snackbarText, setSnackbarText] = useState("");

  const onChangeAmount = (e) => {
    const val = e.target.value;
    if (!val) setAmount("");
    else if (BigNumber.from(val).lt(1)) setAmount("1");
    else setAmount(val);
  };

  const addCredits = async () => {
    try {
      if (!isAddress(recipientAccount) || recipientAccount === ZeroAddress) {
        // alert("Invalid recipient address");
        setSnackbarHeader("Validation Error");
        setSnackbarText("Invalid recipient address");
        setIsError(true);
        return;
      }
      if (!amount) {
        setSnackbarHeader("Validation Error");
        setSnackbarText("Invalid amount");
        setIsError(true);
        return;
      }
      setIsLoading(true);
      const dappDnsNewInstance = getContract(
        dappsDnsNewAddress(supportedChain),
        dappsDnsNewABI.abi,
        library,
        account
      );
      const tx = await dappDnsNewInstance.addCredits(recipientAccount, amount);
      await tx.wait();
      setSnackbarHeader("Transaction successful");
      setSnackbarText("Credits sent successfully to the user");
      setSuccess(true);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 3000)
    } catch (error) {
      console.log("Error in addCredits: ", error);
      setSnackbarHeader("Transaction failed");
      setSnackbarText("Transaction failed due to some error");
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <PersonilizeContainer>
      <div className="align-center">
        <CreditCard>
          <CenterWrapper>
            <Heading>Credit System</Heading>
            <CardWrapper>
              <Multiple onClick={handleMultiple}>Send Multiple </Multiple>

              <img src={QuestionMark} style={{ cursor: "pointer" }} />
            </CardWrapper>
          </CenterWrapper>

          <CreditInputWrapper style={{ width: "100%" }}>
            <TextFieldLabel>Enter recipient wallet address</TextFieldLabel>
            <CreditTextField
              placeholder="wallet add"
              style={{ width: "100%" }}
              value={recipientAccount}
              onChange={(e) => setRecipientAccount(e.target.value)}
            />
          </CreditInputWrapper>
          <CreditInputWrapper style={{ width: "100%" }}>
            <TextFieldLabel>Enter Credit</TextFieldLabel>
            <CreditTextField
              placeholder="Credit"
              style={{ width: "100%" }}
              type="number"
              value={amount}
              onChange={onChangeAmount}
            />
          </CreditInputWrapper>

          <ButtonWrapper style={{ width: "100%" }}>
            {isLoading ? (
              <CreditFillButton onClick={addCredits}>
                <LoaderClip color="var(--primary-text-color)" size={16} />{" "}
                &nbsp;&nbsp;Submit
              </CreditFillButton>
            ) : (
              <CreditFillButton onClick={addCredits}>Submit</CreditFillButton>
            )}
          </ButtonWrapper>
        </CreditCard>
      </div>
      <Success
        open={isSuccess}
        handleClose={() => setSuccess(false)}
        Message={snackbarHeader}
        Text={snackbarText}
      />
      <Error
        open={isError}
        handleClose={() => setIsError(false)}
        Message={snackbarHeader}
        Text={snackbarText}
      />
    </PersonilizeContainer>
  );
};

export default CreditSystem;

/* Denied Modal */
{
  /* <CustomizedDialogs
        open={true}
        onClose={() => {}}
        title="Permission Denied"
      >
        <DeniedModal />
      </CustomizedDialogs> */
}

/* Verify Modal */

{
  /* <CustomizedDialogs
  open={false}
  onClose={() => {}}
  title="Verifying wallet address"
  >
  <VerifyModal />
  </CustomizedDialogs> */
}
